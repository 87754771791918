import classNames from 'classnames';
import React, { FC } from 'react';

import { localeLegacy } from '@lib/tools/locale/source/pmi/legacy';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

const TasteAdvisorGeoblock: FC = () => (
  <div className="tasteAdvisor-gate">
    <div className="d-flex text-center flex-column align-center popup position-absolute w-100vw h-100vh">
      <div
        className={classNames('font-ta-v2 d-flex text-center flex-column text-bold mx-auto justify-content-center', {
          'text-uppercase text_title_geoblock': true,
        })}
      >
        <p>
          <LocaleFragment message={localeLegacy.geoBlock.title1} />
        </p>

        <p>
          <LocaleFragment message={localeLegacy.geoBlock.title2} />
        </p>

        <p>
          <LocaleFragment message={localeLegacy.geoBlock.title3} />
        </p>

        <p>
          <LocaleFragment message={localeLegacy.geoBlock.title4} />
        </p>
      </div>
    </div>
  </div>
);

export { TasteAdvisorGeoblock as TasteAdvisorGeoblockWithoutMemo };
export default React.memo(TasteAdvisorGeoblock);
