import classNames from 'classnames';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TLanguage } from '@lib/core/retailers/types';
import { selectLocale } from '@lib/core/service/selectors';
import { setServiceLocale } from '@lib/core/service/slices';

interface Props {
  supportedLanguages: TLanguage[];
  className?: string;
}

const LanguageSwitcherComponent: FC<Props> = ({ supportedLanguages, className }) => {
  const dispatch = useDispatch();

  const currentLanguage = useSelector(selectLocale);

  const onLanguageSelect = code => {
    dispatch(setServiceLocale(code));
  };

  return (
    <div className={classNames('taste-advisor-language-switcher', className)}>
      {supportedLanguages.length > 1
        ? supportedLanguages.map(({ code, language }) => (
            <button
              key={code}
              className={classNames('pointer switcher-btn ta-start-page-language-switcher', {
                'font-weight-bold': currentLanguage === code,
              })}
              onClick={() => onLanguageSelect(code)}
            >
              {language}
            </button>
          ))
        : null}
    </div>
  );
};

export default LanguageSwitcherComponent;
