import { FC, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { TQuizAnswerData } from '@lib/core/quizzes/types';
import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { useHeight } from '@lib/tools/views/hooks';

import CTAButton from '@components/pmi/src/atoms/CTA/Button';
import HealthWarningFooterToIsrael from '@components/pmi/src/common/HealthWarningFooterToIsrael';
import { useCurrentQuizBackground } from '@components/pmi/src/hooks/useCurrentQuizBackground';
import QuestionHeader from '@components/pmi/src/organisms/Header/Header';
import HealthWarning from '@components/pmi/src/organisms/HealthWarning/HealthWarning';
import AnswerSectionHeader from '@components/pmi/src/organisms/Quiz/AnswerSectionHeader/AnswerSectionHeader';
import AnswerItem from '@components/pmi/src/organisms/Quiz/QDS8002/AnswerItem/AnswerItem';
import QuizFooter from '@components/pmi/src/organisms/Quiz/QuizFooter/QuizFooter';

interface Props {
  answers: TQuizAnswerData[];
  questionTitle: string;
  questionCaption: string;
  progressPercent: number;
  handleConfirmAnswer: (answerId: string | string[], callback: () => void) => void;
  goBack: () => void;
  goHome: () => void;
  questionImage: string;
  choicesQuantity: {
    maxChoices: number;
    minChoices: number;
  };
  isBackButtonVisible: boolean;
  isBackButtonEnabled: boolean;
  isLoading: boolean;
  isHealthWarningQuizAddon: boolean;
  isHeetsBackgroundEnabled: boolean;
  isLight?: boolean;
  currentPrimaryColor: string;
  isShowRestrictedBackground: boolean;
  isHealthWarningFooterToIsraelAddon?: boolean;
}

const QuizPage: FC<Props> = ({
  isLight = false,
  currentPrimaryColor,
  questionTitle,
  questionCaption,
  answers,
  progressPercent,
  handleConfirmAnswer,
  goBack,
  questionImage,
  choicesQuantity,
  isBackButtonVisible,
  isBackButtonEnabled,
  isLoading,
  isHealthWarningQuizAddon,
  isHeetsBackgroundEnabled,
  isShowRestrictedBackground,
  isHealthWarningFooterToIsraelAddon,
}) => {
  const [footerRef, paddingForFooter] = useHeight<HTMLDivElement>();
  const minChoicesQuantity = choicesQuantity?.minChoices;
  const maxAnswersChoices = choicesQuantity?.maxChoices;
  const isMobileResolution = useMediaQuery({ maxWidth: 767 });
  const [answersArray, setAnswersArray] = useState<string[]>([]);
  const [currentAnswerId, setCurrentAnswerId] = useState<string[]>([]);
  const currentBackground = useCurrentQuizBackground(questionImage, isShowRestrictedBackground);
  const answerIds = answers.map(answer => {
    const answerId = Object.keys(answer)[0];
    return answerId;
  });
  const useAnswersArray = answers.slice(0, 3);

  useEffect(() => {
    if (answersArray.length === 3) {
      setCurrentAnswerId([answerIds[6]]);
    } else if (answersArray.includes(answerIds[0]) && answersArray.includes(answerIds[1])) {
      setCurrentAnswerId([answerIds[3]]);
    } else if (answersArray.includes(answerIds[1]) && answersArray.includes(answerIds[2])) {
      setCurrentAnswerId([answerIds[4]]);
    } else if (answersArray.includes(answerIds[0]) && answersArray.includes(answerIds[2])) {
      setCurrentAnswerId([answerIds[5]]);
    } else if (answersArray.includes(answerIds[0])) {
      setCurrentAnswerId([answerIds[0]]);
    } else if (answersArray.includes(answerIds[1])) {
      setCurrentAnswerId([answerIds[1]]);
    } else if (answersArray.includes(answerIds[2])) {
      setCurrentAnswerId([answerIds[2]]);
    } else {
      setCurrentAnswerId([]);
    }
  }, [answersArray]);

  const handleClick = () => {
    handleConfirmAnswer(currentAnswerId, () => {
      setAnswersArray([]);
      setCurrentAnswerId([]);
    });
  };

  return (
    <div
      className={`ta-v2-qds8007-page ${isHealthWarningFooterToIsraelAddon ? 'padding-bottom-30vh minh-101vh' : 'minh-100vh'}`}
      style={{
        background: currentBackground,
        paddingBottom: paddingForFooter,
      }}
    >
      <QuestionHeader
        caption={questionCaption}
        currentPrimaryColor={currentPrimaryColor}
        goBack={goBack}
        isBackButtonEnabled={isBackButtonEnabled}
        isBackButtonVisible={isBackButtonVisible}
        isHeetsBackgroundEnabled={isHeetsBackgroundEnabled}
        isLoading={isLoading}
        progressPercent={progressPercent}
        title={questionTitle}
      />
      <div className="ta-v2-qds8007-page-content-part">
        <div className="ta-v2-qds8007-answer-section-wrapper">
          <AnswerSectionHeader
            isLight={isLight}
            maxChoicesCount={maxAnswersChoices}
            selectedChoicesCount={answersArray?.length || 0}
          />
          <div className="ta-v2-qds8007-answer-section">
            {useAnswersArray.map(answer => {
              const answerId = Object.keys(answer)[0];
              return (
                <AnswerItem
                  key={answerId}
                  answerId={answerId}
                  answerImage={answer[answerId].image}
                  answerText={answer[answerId].text}
                  answersArray={answersArray}
                  currentPrimaryColor={currentPrimaryColor}
                  isLight={isLight}
                  maxAnswersChoices={maxAnswersChoices}
                  setAnswersArray={setAnswersArray}
                />
              );
            })}
          </div>
        </div>
        {!isMobileResolution && maxAnswersChoices > 1 ? (
          <div className="ta-v2-cta-btn-margin">
            <CTAButton
              btnText={<LocaleFragment message={localeV2.common.nextQuestion} />}
              isDark={answersArray.length >= minChoicesQuantity}
              isDisabled={answersArray.length < minChoicesQuantity}
              onBtnClick={handleClick}
            />
          </div>
        ) : null}
      </div>
      <div ref={footerRef} className="ta-v2-quiz-footer-wrapper">
        {isMobileResolution && maxAnswersChoices > 1 ? (
          <QuizFooter handleClick={handleClick} isNextButtonEnabled={answersArray.length >= minChoicesQuantity} />
        ) : null}
        {isHealthWarningQuizAddon && <HealthWarning />}
      </div>
      {isHealthWarningFooterToIsraelAddon && <HealthWarningFooterToIsrael />}
    </div>
  );
};

export default QuizPage;
