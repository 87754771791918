import cn from 'classnames';
import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import Logo from '@app/pmi/src/assets/media/V2/Iqos.png';

import { TLanguage } from '@lib/core/retailers/types';
import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import CTAButton from '@components/pmi/src/atoms/CTA/Button';
import LanguageSwitcher from '@components/pmi/src/organisms/LanguageSwitcher/LanguageSwitcher';

interface Props {
  supportedLanguages: TLanguage[];
  selectedLanguageCode: string;
  onLanguageSelect: (code: string) => void;
  onAgree: () => void;
}

const LandingPage: FC<Props> = ({ selectedLanguageCode, supportedLanguages, onLanguageSelect, onAgree }) => {
  const isMobileResolution = useMediaQuery({ maxWidth: 767 });
  const currentFont = isMobileResolution ? 'font-ta-v2-global-body-small' : 'font-ta-v2-super-paragraph-small';
  return (
    <div className="ta-v2-disclaimer-page">
      <div className="ta-v2-disclaimer-page-header">
        <img alt="logo" className="ta-v2-disclaimer-page-header-logo" src={Logo} />
      </div>
      <div className="ta-v2-disclaimer-page-content">
        <LanguageSwitcher
          isLight={false}
          selectedLanguageCode={selectedLanguageCode}
          supportedLanguages={supportedLanguages}
          onLanguageSelect={onLanguageSelect}
        />
        <div className={currentFont}>
          <LocaleFragment message={localeV2.disclaimerPage.firstPart} />
        </div>
        <div className={currentFont}>
          <LocaleFragment message={localeV2.disclaimerPage.secondPart} />
        </div>
        <div className={currentFont}>
          <LocaleFragment message={localeV2.disclaimerPage.thirdPart} />
        </div>
        <CTAButton
          isDark
          btnText={<LocaleFragment message={localeV2.disclaimerPage.ctaText} />}
          className={cn({
            'margin-top-15px': !isMobileResolution,
          })}
          onBtnClick={onAgree}
        />
      </div>
    </div>
  );
};

export default LandingPage;
