import { createSelector } from 'reselect';

import { RootState } from '@lib/core/service/types/appStateType';
import { IProfileState } from '@lib/core/users/slices/profile';
import { TProfile } from '@lib/core/users/types';
import { languages } from '@lib/tools/locale/utils/consts';

export const selectProfileState = (state: RootState): IProfileState => state.user.profile;

/**
 * @returns {TProfile} profile data
 */
export const selectProfileData: (state) => TProfile = createSelector([selectProfileState], state => state?.data);

/**
 * @returns {boolean} is profile loading state
 */
export const selectIsProfileLoading: (state) => boolean = createSelector(
  [selectProfileState],
  state => state?.isLoading,
);

/**
 * @returns {number | null} the user profile birth year
 */
export const selectUserProfileBirthYear: (state) => number | null = createSelector(
  [selectProfileData],
  state => state?.birth_year || null,
);

/**
 * @returns {string} the user profile gender slug
 */
export const selectUserProfileGenderSlug: (state) => string = createSelector(
  [selectProfileData],
  state => state?.gender?.slug || '',
);

/**
 * @returns {string} the user profile country
 */
export const selectUserProfileCountry: (state) => string = createSelector(
  [selectProfileData],
  state => state?.country || '',
);

/**
 * @returns {string} the user profile language
 */
export const selectUserProfileLanguage: (state) => string = createSelector(
  [selectProfileData],
  state => state?.language || languages.ENGLISH,
);

/**
 * @returns the user profile Id
 */
export const selectUserProfileId: (state) => string = createSelector(
  [selectProfileState],
  state => state?.data?.identifier,
);
