import { useNavigate } from 'react-router-dom';

import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useApp } from '@lib/core/service/hooks';
import { setServiceLocale } from '@lib/core/service/slices';
import { prependBasename } from '@lib/core/service/utils';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';
import { PAGES } from '@lib/tools/views/urls';

import DisclaimerPage from '@components/pmi/src/templates/DisclaimerDTE/Disclaimer';

function DisclaimerContainer() {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const { locale } = useApp();
  const { retailerLanguages } = useRetailer();

  const handleOnAgree = () => {
    navigate(prependBasename(PAGES.ta.landingRemote));
  };

  const onLanguageSelect = code => {
    dispatch(setServiceLocale(code));
  };

  return (
    <DisclaimerPage
      selectedLanguageCode={locale}
      supportedLanguages={retailerLanguages}
      onAgree={handleOnAgree}
      onLanguageSelect={onLanguageSelect}
    />
  );
}

export default DisclaimerContainer;
