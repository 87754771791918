import { TProductCategory } from '@lib/core/products/types';
import { isApplicationKiosk } from '@lib/core/service/consts';
import { getRequestCommonParameters } from '@lib/core/service/requests/api';

/**
 * Used for get and update user data
 *
 * @returns {string} url for get user data based on retailer if ID is provided
 */
export const userDataApiUrlCreator = () => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();
  return `/users/${retailerSlug}/${retailerLocationSlug}/refresh-user/`;
};

/**
 * Use to get and update user profile data
 *
 * @returns {string} url
 */
export const profileDataApiUrlCreator = () => {
  const { retailerSlug, retailerLocationSlug, userProfileId } = getRequestCommonParameters();
  return `/${retailerSlug}/${retailerLocationSlug}/users/profile/${userProfileId}/`;
};

/**
 * Used to get and update user wishlist data
 *
 * @returns {string} url for get user wishlist
 */
export const userWishListApiUrlCreator = () => {
  const { retailerSlug, retailerLocationSlug, userId } = getRequestCommonParameters();

  return `/users/${userId}/retailer/${retailerSlug}/retailer-location/${retailerLocationSlug}/wishlist/`;
};

/**
 * Used to get all product lists
 * Create a new list: @param { list_name: '', product: '' }
 * Add a new new item: @param { list_name: '', product: '', list_slug: '' }
 *
 * @returns {string} url
 */
export const productListApiUrlCreator = () => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();

  return `/${retailerSlug}/${retailerLocationSlug}/users/product-list/`;
};

/**
 * Used to get specific product list data
 *
 * @returns {string} url
 */
export const productListProductApiUrlCreator = (listSlug: string) => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();

  return `/${retailerSlug}/${retailerLocationSlug}/users/product-list/${listSlug}/product/`;
};

/**
 * Used to remove a product from the specific list
 *
 * @returns {string} url
 */
export const updateProductListProductApiUrlCreator = (listSlug: string, productId: string) => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();

  return `/${retailerSlug}/${retailerLocationSlug}/users/product-list/${listSlug}/product/${productId}/`;
};

/**
 * Used to get, post, remove location
 *
 * @returns {string} url
 */
export const locationListApiUrlCreator = (locationSlug?: string) => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();

  return `/${retailerSlug}/${retailerLocationSlug}/users/loc-list/${locationSlug ? `${locationSlug}/` : ''}`;
};

/**
 * Used to retrieve user feedbacks
 *
 * @returns {string} url
 */
export const feedbackDataApiUrlCreator = ({ productId = '' }: { productId?: string }) => {
  const { retailerSlug, retailerLocationSlug, userSessionId } = getRequestCommonParameters();

  return `/users/${retailerSlug}/${retailerLocationSlug}/feedback/${userSessionId}/product/${productId ? `${productId}/` : ''}`;
};

/**
 * Used to retrieve products suggestion for the Taste Path feature
 *
 * @returns {string} url
 */
export const productsSuggestionApiUrlCreator = ({ productCategory = '' }: { productCategory: TProductCategory }) => {
  const { retailerSlug, retailerLocationSlug, userSessionId } = getRequestCommonParameters();

  return `/products/${retailerSlug}/${retailerLocationSlug}/suggestion/${productCategory}/${userSessionId}/`;
};

/**
 * Send email and password as auth method
 */
export const loginApiUrlCreator = () => {
  if (isApplicationKiosk) {
    return `/users/rest-auth/token/`;
  }

  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();
  return `/users/${retailerSlug}/${retailerLocationSlug}/rest-auth/token/`;
};

/**
 * Send token obtained from react-oauth/google to our back-end as auth method
 */
export const googleLoginApiUrlCreator = () => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();
  return `/users/${retailerSlug}/${retailerLocationSlug}/rest-auth/google/`;
};

/**
 * Send token obtained from react-oauth/google to our back-end as auth method
 */
export const facebookLoginApiUrlCreator = () => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();
  return `/users/${retailerSlug}/${retailerLocationSlug}/rest-auth/facebook/`;
};

/**
 * Send auth data for register user
 */
export const registrationApiUrlCreator = () => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();
  return `/users/${retailerSlug}/${retailerLocationSlug}/email-registration/`;
};

/**
 * Send email to start password reset process
 */
export const resetPasswordApiUrlCreator = () => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();
  return `/users/${retailerSlug}/${retailerLocationSlug}/rest-auth/password/reset/`;
};

/**
 * Send email to set new password
 */
export const setNewPasswordApiUrlCreator = () => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();
  return `/users/${retailerSlug}/${retailerLocationSlug}/rest-auth/password/reset/confirm/`;
};

/**
 * Used for upload user picture (avatar) and attach it to user profile
 *
 * @returns {string} url for upload user picture (avatar) and attach it to user profile
 */
export const uploadUserPictureApiUrlCreator = () => {
  const { userId } = getRequestCommonParameters();
  return `/users/${userId}/s3-picture-upload/`;
};

export const postUserFeedback = () => {
  return `/users/feedback/`;
};

export const fidelityCardApiUrlCreator = ({ fidelityCardCode = '' }) => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();
  return `/${retailerSlug}/${retailerLocationSlug}/users/fidelity-card/${fidelityCardCode ? `${fidelityCardCode}/` : ''}`;
};
export const getProfileCharactersApiUrlCreator = () => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();
  return `/${retailerSlug}/${retailerLocationSlug}/users/character/`;
};
