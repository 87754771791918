import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// eslint-disable-next-line import/order
import { actionGetQuizzes } from '@lib/core/quizzes/slices';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { actionGetRetailerDetail, actionGetRetailerLocations } from '@lib/core/retailers/slices';
import { TLanguage } from '@lib/core/retailers/types';
import { PRODUCT_CATEGORY_NONE, STATE, isApplicationKiosk, isApplicationPmi } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { selectRouteBasename } from '@lib/core/service/selectors/routes';
import { setProductCategory, setServiceLocale } from '@lib/core/service/slices';
import { actionGetClientIp } from '@lib/core/service/slices/technical/clientIp';
import { migrateAppSliceToServiceSlice } from '@lib/core/service/utils';
import { history } from '@lib/core/service/utils/Navigator';
import { useUser } from '@lib/core/users/hooks';
import { actionResetUserSlice } from '@lib/core/users/slices/user';
import UserUtils from '@lib/core/users/utils/users';
import { isAppInIframe } from '@lib/tools/comms/utils';
import { actionEnableLogging } from '@lib/tools/dat/slices';
import RouteUtils from '@lib/tools/routes';
import { setRouteBasename } from '@lib/tools/routes/slices/route';
import { useRetailerDesignSet, useTypedSelector } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import SpinnerTA from '@components/pmi/src/common/Spinner';
import SpinnerVH from '@components/web/src/components/GenericSpinner/GenericSpinner';

// Non-view code requires the history object to be accessible.

/**
 * * Service Launcher (HOC)
 *
 * 1. Fetches retailer and retailer location data.
 * 2. Sets app locale based on the retailer's default language and URL locale.
 * 3. Configures default product category different types of apps.
 * 4. Generates the basename for navigation and handles entrypoints.
 * 5. Pre-fetches relevant resources like quizzes.
 *
 * @summary Designed to handle locale configuration, retailer and location setup, product category selection,
 * and generates the basename for window navigation.
 */
export default Component => {
  const ServiceLauncher: FC<JSX.Element> = (App: JSX.Element) => {
    const dispatch = useDispatch();

    const { locale, productCategory, isDevToolsEnabled } = useApp();
    const { retailerId, retailerSlug, retailerDefaultLanguage, retailerLanguages } = useRetailer();
    const { retailerLocationId, retailerLocationProductCategories, addOns } = useRetailerLocation();
    const { kioskUserRetailerSlug, kioskUserRetailerLocationId, isUserKioskAdmin, userProfileId } = useUser();

    const { isDesignSetVinhoodApp } = useRetailerDesignSet();

    const routeBasename = useSelector(selectRouteBasename);

    const appProductCategory = useTypedSelector(state => state.service.productCategory);

    const isKioskLoggedOut = isApplicationKiosk && !isUserKioskAdmin;
    const isPmiEcommerceLoading = isAppInIframe && isApplicationPmi && (!retailerLocationId || !retailerId);

    const urlRetailerSlug = RouteUtils.getRetailerSlug();
    const urlRetailerLocationId = RouteUtils.getRetailerLocationId();
    const currentRetailerSlug = isUserKioskAdmin ? kioskUserRetailerSlug : urlRetailerSlug;
    const currentRetailerLocationId = isUserKioskAdmin ? kioskUserRetailerLocationId : urlRetailerLocationId;
    const isRetailerUnset = urlRetailerSlug !== retailerSlug && !isApplicationKiosk;
    const isKioskActive = isApplicationKiosk && isUserKioskAdmin;

    const isBasicDataLoaded =
      !!userProfileId && ((isKioskActive && addOns.length) || (!isKioskActive && !!retailerId && !!retailerLocationId));

    // useWhatChanged([isAppLaunched, userRole, locale, appProductCategory]);

    /**
     * * Locale setup to verify if the language is supported by the retailer
     */
    useEffect(() => {
      const localeFromUrl = RouteUtils.getLocale();
      if (retailerLanguages.length) {
        const isValidUrlLocale = retailerLanguages.some((language: TLanguage) => language.code === localeFromUrl);
        dispatch(setServiceLocale(isValidUrlLocale ? localeFromUrl : retailerDefaultLanguage));
      }
    }, [retailerLanguages]);

    /**
     * * Load retailer
     */
    useEffect(() => {
      if (currentRetailerSlug && (isRetailerUnset || isKioskActive)) {
        if (isApplicationPmi) {
          // ! Temp change to test performance
          Promise.resolve(dispatch(actionGetRetailerDetail({ retailerSlug: currentRetailerSlug }))).then(() => {
            dispatch(actionGetClientIp());

            dispatch(
              actionGetRetailerLocations({
                retailerLocationId: currentRetailerLocationId,
                retailerSlug: currentRetailerSlug,
              }),
            );
          });
        } else {
          Promise.resolve(dispatch(actionGetClientIp())).then(() => {
            dispatch(actionGetRetailerDetail({ retailerSlug: currentRetailerSlug }));
            dispatch(
              actionGetRetailerLocations({
                retailerLocationId: currentRetailerLocationId,
                retailerSlug: currentRetailerSlug,
              }),
            );
          });
        }
      }

      // Logout kiosk when not in kiosk env
      if (!isApplicationKiosk && isUserKioskAdmin) {
        dispatch(actionResetUserSlice());
      }
    }, [retailerSlug, isUserKioskAdmin]);

    /**
     * * Handle default product category
     */
    useEffect(() => {
      const urlProductCategory = RouteUtils.getProductCategory();
      const { kioskUserProductCategory } = UserUtils.getKioskUser();

      // This check is needed for handling entrypoints without productCategory in the URL for widgets.
      // `isDesignSetVinhoodExperience` will return `false` if there's no productCategory in the URL.
      const isVinhoodExperience = !isApplicationPmi && !isApplicationKiosk && !isDesignSetVinhoodApp;

      const shouldFallbackToFirstProductCategory =
        retailerLocationProductCategories.length === 1 ||
        (isVinhoodExperience && retailerLocationProductCategories.length > 1 && !urlProductCategory);
      if (isBasicDataLoaded) {
        if (RouteUtils.isPageRemoteGuidedTrialGenerator()) {
          dispatch(setProductCategory(PRODUCT_CATEGORY_NONE));
        } else if (productCategory === PRODUCT_CATEGORY_NONE && !isUserKioskAdmin) {
          if (shouldFallbackToFirstProductCategory) {
            dispatch(setProductCategory(retailerLocationProductCategories[0]));
          } else if (urlProductCategory) {
            dispatch(setProductCategory(urlProductCategory));
          } else {
            dispatch(setProductCategory(PRODUCT_CATEGORY_NONE));
          }
        } else if (isApplicationKiosk) {
          // Kiosk logins
          dispatch(setProductCategory(kioskUserProductCategory));
        }
      }
    }, [retailerId, retailerLocationId, isUserKioskAdmin, isBasicDataLoaded]);

    /**
     * * Generate basename for routing
     */
    useEffect(() => {
      const basename = RouteUtils.generateBasename();
      const urlProductCategory = RouteUtils.getProductCategory();

      if (isBasicDataLoaded && appProductCategory) {
        if (basename) {
          const isStaleProductCategory = productCategory !== urlProductCategory;
          const resetBasename =
            !window.location.pathname.includes(basename) || isStaleProductCategory || isDesignSetVinhoodApp;

          if (!routeBasename || resetBasename) {
            dispatch(setRouteBasename({ basename }));
          }
        }
      }
    }, [isBasicDataLoaded, locale, appProductCategory]);

    /**
     * ? Move logic into routers
     * * Handle entrypoints
     */
    useEffect(() => {
      let [page] = RouteUtils.getPage().split('?');
      const params = RouteUtils.getSearch();
      if (appProductCategory) {
        if (page === '/') {
          if (isApplicationPmi) {
            if (isAppInIframe) {
              page = PAGES.ta.quiz.local;
            } else {
              page = PAGES.ta.landing;
            }
          } else if (isApplicationKiosk) {
            if (isUserKioskAdmin) {
              page = PAGES.vinhood.welcome;
            } else {
              page = PAGES.vinhood.kioskLogin;
            }
          } else if (!isDesignSetVinhoodApp) {
            page = PAGES.vinhood.home;
          }
        }
        // * Replace window location to force API translations for essences.
        if (history && isBasicDataLoaded) history.push(routeBasename + page + (params || ''));
      }
    }, [appProductCategory, isUserKioskAdmin, routeBasename, isBasicDataLoaded]);

    // Pre-fetch relevant resources
    useEffect(() => {
      if (isBasicDataLoaded && retailerSlug && !isApplicationPmi) {
        dispatch(actionGetQuizzes());
      }
    }, [isBasicDataLoaded, retailerSlug]);

    /**
     * * Clears states prior to v0.5.0
     */
    useEffect(() => {
      localStorage.removeItem(STATE);
      migrateAppSliceToServiceSlice();
      if (isKioskLoggedOut) {
        // this is needed to retrieve the recaptcha information in the kiosk login page
        dispatch(actionGetClientIp());
      }
    }, []);

    /**
     * * Enable logging
     */
    useEffect(() => {
      if (isDevToolsEnabled) {
        dispatch(actionEnableLogging());
      }
    }, [isDevToolsEnabled]);

    const loader = isApplicationPmi ? <SpinnerTA /> : <SpinnerVH />;
    return isBasicDataLoaded || isPmiEcommerceLoading || isKioskLoggedOut ? <Component {...App} /> : loader;
  };

  return ServiceLauncher;
};
