import { defineMessages } from '@formatjs/intl';

export const localeTEREA = {
  landing: defineMessages({
    findYourWayTo: {
      defaultMessage: 'Find your way to',
      id: 'taste-advisor.select-start-page.header_1',
    },
    flavours: {
      defaultMessage: 'flavours.',
      id: 'taste-advisor.select-start-page.header_2',
    },
    privacyLink: {
      defaultMessage: 'PMI privacy policy',
      id: 'taste-advisor.select-start-page.privacy_footer_3',
    },
    privacyText: {
      defaultMessage: 'Your data will be processed in accordance to the',
      id: 'taste-advisor.select-start-page.privacy_footer_2',
    },
    selectorDescriptionText: {
      defaultMessage: 'Follow the Flavour Guide for IQOS and find new flavours crafted for your taste.',
      id: 'taste-advisor.select-start-page.discover',
    },
    selectorDisclaimerText: {
      defaultMessage:
        'Flavour quiz helps us to understand your taste preferences in general, to make further recommendations on flavours. It does not imply that any products mentioned in the quiz are linked to the product flavours.',
      id: 'taste-advisor.select-start-page.privacy_footer_1',
    },
  }),
  result: defineMessages({
    mentholDisclaimer: {
      defaultMessage:
        '*This product contains menthol. Flavourings should not be confused with the additives contained in tobacco sticks. Find out more: www.philipmorris.ch.',
      id: 'taste-advisor.iqos.menthol_disclaimer_messages',
    },
  }),
};
