import classNames from 'classnames';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { ReactComponent as ArrowIcon } from '@app/pmi/src/assets/media/caret.svg';

interface ProductCategoriesDropDownProps {
  productCategories: string[];
  selectedProductCategory: string;
  setSelectedDesignSet: Dispatch<SetStateAction<string>>;
}

const ProductCategoriesDropDown: FC<ProductCategoriesDropDownProps> = ({
  productCategories,
  selectedProductCategory,
  setSelectedDesignSet,
}) => {
  const isMobileResolution = useMediaQuery({ maxWidth: 767 });
  const [isVisible, setIsVisible] = useState(false);

  const handleProductCategory = item => {
    setSelectedDesignSet(item);
    setIsVisible(false);
  };

  return (
    <div className="dropdown-ds">
      <button
        className={`dropdown-toggle-ds text-uppercase ${isMobileResolution ? 'font-ta-v2-custom-small-header-bold' : 'font-ta-v2-global-caption-medium'}`}
        type="submit"
        onClick={() => setIsVisible(!isVisible)}
      >
        {selectedProductCategory}
        <ArrowIcon
          className={classNames('toggle-icon', {
            open: isVisible,
          })}
        />
      </button>
      <div
        className={classNames('dropdown-menu-ds', {
          open: isVisible,
        })}
      >
        {productCategories.map((item, index) => (
          <button
            key={index}
            className={`dropdown-menu-ds-btn ${isMobileResolution ? 'font-ta-v2-global-regulatory-text-medium' : 'font-ta-v2-global-body-large'}`}
            type="button"
            value={item}
            onClick={() => {
              handleProductCategory(item);
            }}
            onKeyDown={() => {
              handleProductCategory(item);
            }}
          >
            <p className="mb-0 text-uppercase ta-dropdown-text">{item}</p>
          </button>
        ))}
      </div>
    </div>
  );
};

interface Props {
  productCategories: string[];
  selectedProductCategory: string;
  setSelectedDesignSet: Dispatch<SetStateAction<string>>;
}
const ProductCategories: FC<Props> = ({ productCategories, selectedProductCategory, setSelectedDesignSet }) => (
  <>
    {productCategories.length > 1 ? (
      <ProductCategoriesDropDown
        productCategories={productCategories}
        selectedProductCategory={selectedProductCategory}
        setSelectedDesignSet={setSelectedDesignSet}
      />
    ) : null}
  </>
);

export default ProductCategories;
