import { selectRetailerLocationProductCategories } from '@lib/core/retailers/selectors/retailerLocation';
import { B2C_EXPOSURE_TAGS } from '@lib/core/service/consts';
import {
  IUserProductPreferencesExposure,
  IUserProductPreferencesState,
} from '@lib/core/service/slices/productPreferencesSlice';
import { store } from '@lib/core/service/store';
import {
  selectKioskUserProductCategory,
  selectKioskUserRetailerLocationId,
  selectKioskUserRetailerSlug,
  selectUserCharacters,
  selectUserEmail,
  selectUserLanguage,
} from '@lib/core/users/selectors/user';
import { TUser } from '@lib/core/users/types';
import { findUserProductPreferencesActiveSliceState } from '@lib/core/users/utils/productPreferences';
import { EXPOSURE_PREFERENCES } from '@lib/tools/shared/helpers/consts';
/**
 * Utility class for working with user data.
 */

type IUserInformationCompleteType = {
  userData: TUser | Record<string, never>;
  excludeProductPreferencesValue: boolean;
  userProductPreferences?: IUserProductPreferencesState;
};

type IProductPreferenceEnabledType = {
  userProductPreferences: IUserProductPreferencesState;
  exposure: IUserProductPreferencesExposure;
};

type IReturnType = {
  isProfileCompleted: boolean;
  profilePercent: number;
  isSettingsDataCompleted: boolean;
  isTagsDataCompleted: boolean;
};

class UserUtils {
  /**
   * Retrieves the kiosk user data from the store.
   * @returns The kiosk user data.
   */
  public static getKioskUser() {
    const state = store.getState();

    const userEmail = selectUserEmail(state);
    const kioskUserRetailerSlug = selectKioskUserRetailerSlug(state);
    const userLanguage = selectUserLanguage(state);
    const kioskUserProductCategory = selectKioskUserProductCategory(state);
    const kioskUserRetailerLocationId = selectKioskUserRetailerLocationId(state);
    return {
      kioskUserProductCategory,
      kioskUserRetailerLocationId,
      kioskUserRetailerSlug,
      userEmail,
      userLanguage,
    };
  }

  /**
   * Checks if the user has characters for each retailer location product categories.
   * @returns {boolean}
   */

  public static isUserHasCharactersForEachRetailerLocationCategories() {
    const state = store.getState();
    const userCharacters = selectUserCharacters(state);
    const retailerLocationProductCategories = selectRetailerLocationProductCategories(state);

    const userCharactersProductCategories = userCharacters.map(characterData => characterData?.product_category || '');
    return retailerLocationProductCategories.every(productCategory =>
      userCharactersProductCategories.includes(productCategory),
    );
  }

  /**
   * Checks if the user's information is complete.
   * @param {Object} params - The parameters object.
   * @param {Object} params.userData - The user's data.
   * @param {boolean} params.excludeProductPreferencesValue - Flag to exclude product preferences from the check.
   * @param {Object} params.userProductPreferences - The user's product preferences.
   * @returns {Object} An object containing the completeness status and profile percentage.
   */
  public static isUserInformationComplete = ({
    userData,
    excludeProductPreferencesValue,
    userProductPreferences,
  }: IUserInformationCompleteType): IReturnType => {
    const isEmptyString = (value: string): boolean => !!(!value || !value.trim());
    let profilePercent = 100;
    let isSettingsDataCompleted = true;
    let isTagsDataCompleted = true;

    const {
      first_name: firstName = '',
      last_name: lastName = '',
      country = '',
      profile_image: displayPicture = '',
    } = userData;

    if (isEmptyString(firstName)) profilePercent -= 14;
    if (isEmptyString(lastName)) profilePercent -= 14;
    if (isEmptyString(country)) profilePercent -= 14;
    if (isEmptyString(displayPicture)) profilePercent -= 14;

    if (!excludeProductPreferencesValue) {
      if (!this.checkIsProductPreferencesEnabled({ exposure: EXPOSURE_PREFERENCES, userProductPreferences })) {
        profilePercent -= 22;
      }
      if (profilePercent < 100) isSettingsDataCompleted = false;

      if (!this.checkIsProductPreferencesEnabled({ exposure: B2C_EXPOSURE_TAGS, userProductPreferences })) {
        isTagsDataCompleted = false;
        profilePercent -= 22;
      }
    }

    return {
      isProfileCompleted: profilePercent === 100,
      isSettingsDataCompleted,
      isTagsDataCompleted,
      profilePercent,
    };
  };

  /**
   * Checks if a specific product preference is enabled for the user.
   * @param {Object} params - The parameters object.
   * @param {Object} params.userProductPreferences - The user's product preferences.
   * @param {string} params.exposure - The exposure or type of product preferences to check.
   * @returns {boolean} `true` if the specified product preference is enabled, `false` otherwise.
   */
  public static checkIsProductPreferencesEnabled = ({
    userProductPreferences,
    exposure,
  }: IProductPreferenceEnabledType): boolean => {
    const fieldState = findUserProductPreferencesActiveSliceState(exposure);

    if (userProductPreferences?.[fieldState]) {
      let isPreferenceEnabled = false;
      Object.keys(userProductPreferences[fieldState]).forEach(preferenceKey => {
        if (userProductPreferences[fieldState][preferenceKey].isEnabled) isPreferenceEnabled = true;
      });

      return isPreferenceEnabled;
    }
    return false;
  };
}

export default UserUtils;
