import cn from 'classnames';
import { useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';

import Logo from '@app/pmi/src/assets/media/V2/Iqos.png';

import { TLanguage } from '@lib/core/retailers/types';
import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import CTAButton from '@components/pmi/src/atoms/CTA/Button';
import LanguageSwitcher from '@components/pmi/src/organisms/LanguageSwitcher/LanguageSwitcher';

interface Props {
  supportedLanguages: TLanguage[];
  selectedLanguageCode: string;
  onLanguageSelect: (code: string) => void;
  onClose: () => void;
}

const CustomInfoPage = ({ selectedLanguageCode, supportedLanguages, onLanguageSelect, onClose }: Props) => {
  const isMobileResolution = useMediaQuery({ maxWidth: 767 });
  const currentFont = isMobileResolution ? 'font-ta-v2-global-body-small' : 'font-ta-v2-super-paragraph-small';

  const { customInfoPage } = localeV2;

  const refToTop = useRef<HTMLInputElement>(null);
  useEffect(() => {
    return refToTop.current && refToTop.current.scrollIntoView();
  });

  return (
    <div ref={refToTop} className="ta-v2-custom-info-page">
      <div className="ta-v2-custom-info-page-header">
        <img alt="logo" className="ta-v2-custom-info-page-header-logo" src={Logo} />
      </div>
      <div className="ta-v2-custom-info-page-content">
        <LanguageSwitcher
          isLight={false}
          selectedLanguageCode={selectedLanguageCode}
          supportedLanguages={supportedLanguages}
          onLanguageSelect={onLanguageSelect}
        />

        <div className={`${currentFont} ta-v2-custom-info-page-message`}>
          <LocaleFragment
            message={customInfoPage.message}
            transformText="custom-pmi"
            insertIntlJSXPartOptions={{
              className: '',
              linkId: 'custom-info-message-link',
              linkRender: text => (
                <span
                  aria-hidden
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={() => window.open('https://ec.europa.eu/consumers/odr/', '_blank')}
                >
                  <p className="no-margin d-inline">{text}</p>{' '}
                </span>
              ),
            }}
          />
        </div>

        <CTAButton
          isDark
          btnText={<LocaleFragment message={customInfoPage.ctaText} />}
          className={cn({
            'margin-top-15px': !isMobileResolution,
          })}
          onBtnClick={onClose}
        />
      </div>
    </div>
  );
};

export default CustomInfoPage;
