import { FC } from 'react';

import AnswerTestV2QDS8001 from '@components/pmi/src/templates/Quiz/QDS8001/QuizPage';

interface Props {
  isHeetsBackgroundEnabled: boolean;
}

const LoadingPageV2: FC<Props> = ({ isHeetsBackgroundEnabled }) => {
  const choicesQuantity = {
    maxChoices: 1,
    minChoices: 1,
  };

  return (
    <AnswerTestV2QDS8001
      isHideProgressBar
      isLoading
      answers={null}
      choicesQuantity={choicesQuantity}
      goBack={() => {}}
      handleConfirmAnswer={() => {}}
      isBackButtonEnabled={false}
      isBackButtonVisible={false}
      isHeetsBackgroundEnabled={isHeetsBackgroundEnabled}
      isShowRestrictedBackground={false}
      progressPercent={0}
      questionCaption=""
      questionImage=""
      questionTitle=""
      selectedChoicesCount={0}
    />
  );
};

export default LoadingPageV2;
