import classNames from 'classnames';
import { Dispatch, FC, SetStateAction } from 'react';

import { IRetailerLanguage } from '@lib/core/retailers/types/retailer';

interface Props {
  languages: IRetailerLanguage[];
  setSelectedLanguage: Dispatch<SetStateAction<IRetailerLanguage>>;
  selectedLanguage: IRetailerLanguage;
  isLayoutRightToLeft: boolean;
}

const LanguageSelector: FC<Props> = ({ languages, setSelectedLanguage, selectedLanguage, isLayoutRightToLeft }) => (
  <div className="languages-menu">
    {languages.map(language => (
      <div key={language.code} className="languages-menu-item">
        <div
          className="pointer checkbox"
          role="presentation"
          onClick={() => {
            setSelectedLanguage(language);
          }}
          onKeyDown={() => {
            setSelectedLanguage(language);
          }}
        >
          <div className={`${language.language === selectedLanguage?.language ? 'selected' : 'not-selected'}`} />
        </div>
        <span
          className={classNames('ta-dropdown-text-small-bold', 'text-uppercase', {
            'letter-spacing-zero': isLayoutRightToLeft,
          })}
        >
          {language.language}
        </span>
      </div>
    ))}
  </div>
);

export default LanguageSelector;
