import cn from 'classnames';
import { FC } from 'react';

import slateBackgroundImage from '@app/pmi/src/assets/media/V2/v2_slate_background.png';

import { TProductInstance } from '@lib/core/products/types';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import {
  isCoolingEssencePresent,
  reOrderEssencesForIQOS3Designs,
  reOrderEssencesForIQOS4Designs,
} from '@lib/tools/shared/pmi/essences';
import { HEETS_EU, TEREA_EU } from '@lib/tools/shared/pmi/essences/consts';
import { retailerProductLocationTags } from '@lib/tools/shared/pmi/products';
import { HEETS, SENTIA, TEREA, VEEV } from '@lib/tools/shared/pmi/retailers/consts';
import { useEqualElementsHeightV2 } from '@lib/tools/views/hooks';

import AromaNote from '@components/pmi/src/organisms/Products/AromaNote/AromaNote';
import EssencesBars from '@components/pmi/src/organisms/Products/EssencesBars/BarEssences';
import EssencesEU from '@components/pmi/src/organisms/Products/EssencesEU/EssencesEU';
import NicotineLevels from '@components/pmi/src/organisms/Products/NicotineLevels/NicotineLevels';
import { getTobaccoText } from '@components/pmi/src/utils';

interface Props {
  retailerProductLocation: TProductInstance;
  selectedProductCategory: string;
  isAromaNotesDisabledAddon: boolean;
  isAromaNoteSecondaryAddon: boolean;
  isEssenceAromaDisabledAddon: boolean;
  isEssenceBodyDisabledAddon: boolean;
  isEssenceCoolingDisabledAddon: boolean;
  isRestrictedProductCardsAddon: boolean;
  isTereaHideEssencesAddon: boolean;
  isTobaccoExperienceHiddenAddon: boolean;
  isProductLongDescriptionHiddenAddon: boolean;
  isProductShortDescriptionHiddenAddon: boolean;
  isVeev?: boolean;
  nicotineLevelData?: {
    description: string;
    name: string;
  }[];
  isMentholDisclaimerAddon: boolean;
  isRestrictedRecommendationAddon: boolean;
}

const PortfolioCard: FC<Props> = ({
  retailerProductLocation,
  selectedProductCategory,
  isAromaNotesDisabledAddon,
  isAromaNoteSecondaryAddon,
  isEssenceAromaDisabledAddon,
  isEssenceBodyDisabledAddon,
  isEssenceCoolingDisabledAddon,
  isRestrictedProductCardsAddon,
  isTereaHideEssencesAddon,
  isTobaccoExperienceHiddenAddon,
  isVeev,
  nicotineLevelData,
  isProductLongDescriptionHiddenAddon,
  isProductShortDescriptionHiddenAddon,
  isMentholDisclaimerAddon,
  isRestrictedRecommendationAddon,
}) => {
  useEqualElementsHeightV2([
    'ta-v2-portfolio-card-name',
    'ta-v2-portfolio-card-caption',
    'ta-v2-portfolio-card-description',
    'ta-v2-portfolio-card-essences-block',
    'ta-v2-portfolio-card-essences-bar',
    'ta-v2-portfolio-card-tobacco',
    'ta-v2-portfolio-card-veev-nicotine-level-block',
    'ta-v2-portfolio-card-name-levia',
    'ta-v2-portfolio-card-caption-levia',
    'ta-v2-portfolio-card-description-levia',
    'ta-v2-portfolio-card-essences-block-levia',
    'ta-v2-portfolio-card-essences-bar-levia',
    'ta-v2-portfolio-card-tobacco-levia',
  ]);
  const { image } = retailerProductLocation;
  const { essences, name, caption, description, attributes, aromas } = retailerProductLocation.product;
  const {
    background_image_v2: backgroundImage,
    restricted_background_image_v2: restrictedBackgroundImage,
    color_primary: colorPrimary,
    color,
    is_pearl: isPearl,
    product_type: type,
  } = attributes;
  const isPearlType = isPearl === 'true';
  const isLeviaType = type === 'levia';

  const { isEuClassicTobaccoEssencesTag } = retailerProductLocationTags(retailerProductLocation);
  const isCooling = isMentholDisclaimerAddon && isCoolingEssencePresent(essences);

  let currentEssences = essences;
  let currentColor = '';
  let currentBackground = '';

  if (selectedProductCategory === VEEV) {
    if (isRestrictedRecommendationAddon && !!restrictedBackgroundImage) {
      currentBackground = `url(${restrictedBackgroundImage})`;
    } else {
      currentBackground = `url(${backgroundImage})`;
    }
  }

  if (selectedProductCategory === TEREA) {
    currentColor = colorPrimary;

    if (isRestrictedProductCardsAddon) {
      currentBackground = '#34303d';
    } else if (isRestrictedRecommendationAddon && !!restrictedBackgroundImage) {
      currentBackground = `url(${restrictedBackgroundImage}) left top/100% no-repeat, 0% 0%/auto  #34303d`;
    } else {
      currentBackground = `url(${backgroundImage}) left top/100% no-repeat, 0% 0%/auto  #34303d`;
    }

    if (isEuClassicTobaccoEssencesTag) {
      currentEssences = essences.filter(essence => essence.group === TEREA_EU);
    } else {
      currentEssences = reOrderEssencesForIQOS4Designs({
        essences,
        isEssenceAromaDisabled: isEssenceAromaDisabledAddon,
        isEssenceBodyDisabled: isEssenceBodyDisabledAddon,
        isEssenceCoolingDisabled: isEssenceCoolingDisabledAddon,
      });
    }
  }
  if (selectedProductCategory === HEETS) {
    currentColor = color;

    if (isRestrictedProductCardsAddon) {
      currentBackground = `url(${slateBackgroundImage}) left top/contain repeat`;
    } else if (isRestrictedRecommendationAddon && !!restrictedBackgroundImage) {
      currentBackground = `url(${restrictedBackgroundImage}) left top/contain no-repeat, url(${slateBackgroundImage}) left top/contain repeat`;
    } else {
      currentBackground = `url(${backgroundImage}) left top/contain no-repeat, url(${slateBackgroundImage}) left top/contain repeat`;
    }

    if (isEuClassicTobaccoEssencesTag) {
      currentEssences = essences.filter(essence => essence.group === HEETS_EU);
    } else {
      currentEssences = reOrderEssencesForIQOS3Designs({
        essences,
        isEssenceAromaDisabled: isEssenceAromaDisabledAddon,
        isEssenceBodyDisabled: isEssenceBodyDisabledAddon,
        isEssenceCoolingDisabled: isEssenceCoolingDisabledAddon,
      });
    }
  }

  return (
    <>
      {isVeev ? (
        <div
          style={{ backgroundColor: color }}
          className={cn('ta-v2-portfolio-card', {
            'ta-v2-portfolio-card-veev': isVeev,
          })}
        >
          <div className="ta-v2-portfolio-card-veev-image-block" style={{ backgroundImage: currentBackground }}>
            <img alt="portfolio card veev img" className="ta-v2-portfolio-card-veev-image" src={image} />
          </div>
          <div className="ta-v2-portfolio-card-veev-content-block">
            <div className="ta-v2-portfolio-card-name font-ta-v2-global-body-large font-weight-bold">{name}</div>

            {!isProductShortDescriptionHiddenAddon && (
              <div className="ta-v2-portfolio-card-caption font-ta-v2-global-regulatory-text-medium font-weight-bold">
                {caption}
              </div>
            )}

            {!isProductLongDescriptionHiddenAddon && (
              <div className="ta-v2-portfolio-card-description font-ta-v2-global-regulatory-text-medium margin-top-8px">
                {description}
              </div>
            )}
          </div>
          <div className="ta-v2-portfolio-card-veev-nicotine-level-block">
            {nicotineLevelData.length ? (
              <div className="ta-v2-portfolio-card-veev-nicotine-level-wrapper">
                <NicotineLevels
                  nicotineLevelData={nicotineLevelData}
                  classname={cn('ta-v2-portfolio-veev-nicotine-level', {
                    'ta-v2-portfolio-veev-nicotine-level-multiple': nicotineLevelData.length > 1,
                  })}
                />
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="ta-v2-portfolio-card" style={{ background: currentBackground }}>
          <div className="ta-v2-portfolio-card-content-block">
            <div className="ta-v2-portfolio-card-content-block-image-wrapper">
              <img alt="portfolio card img" className="ta-v2-portfolio-card-content-block-image" src={image} />
            </div>

            <div
              className={`${isLeviaType ? 'ta-v2-portfolio-card-name-levia' : 'ta-v2-portfolio-card-name'} font-ta-v2-custom-large-paragraph-bold`}
            >
              {name}
            </div>

            {!isProductShortDescriptionHiddenAddon && (
              <div
                className={`${isLeviaType ? 'ta-v2-portfolio-card-caption-levia' : 'ta-v2-portfolio-card-caption'} font-ta-v2-global-body-large font-weight-bold`}
              >
                {`${caption}${isCooling ? '*' : ''}`}
              </div>
            )}

            {!isTobaccoExperienceHiddenAddon ? (
              <div
                className={`${isLeviaType ? 'ta-v2-portfolio-card-tobacco-levia' : 'ta-v2-portfolio-card-tobacco'} font-ta-v2-global-body-large font-weight-bold`}
              >
                <LocaleFragment message={getTobaccoText(retailerProductLocation)} />
              </div>
            ) : null}

            {!isProductLongDescriptionHiddenAddon && (
              <div
                className={`${isLeviaType ? 'ta-v2-portfolio-card-description-levia' : 'ta-v2-portfolio-card-description'} font-ta-v2-global-regulatory-text-medium margin-top-8px`}
              >
                {isEuClassicTobaccoEssencesTag || isLeviaType ? description : null}
              </div>
            )}
          </div>
          <div
            className={`${isLeviaType ? 'ta-v2-portfolio-card-essences-block-levia' : 'ta-v2-portfolio-card-essences-block'}`}
            style={{
              backgroundColor:
                isTereaHideEssencesAddon &&
                !isEuClassicTobaccoEssencesTag &&
                (isAromaNotesDisabledAddon || type === SENTIA) &&
                'transparent',
            }}
          >
            {isEuClassicTobaccoEssencesTag ? (
              <EssencesEU
                isDimensionSmall
                isHorizontalOrientation
                color={currentColor}
                name={currentEssences[0]?.name}
                score={+currentEssences[0]?.score}
              />
            ) : (
              <div
                className={`${isLeviaType ? 'ta-v2-portfolio-card-essences-bar-levia' : 'ta-v2-portfolio-card-essences-bar'} w-100`}
              >
                {isTereaHideEssencesAddon ? null : <EssencesBars color={currentColor} essences={currentEssences} />}
              </div>
            )}
            {isAromaNotesDisabledAddon || isEuClassicTobaccoEssencesTag || type === SENTIA ? null : (
              <AromaNote
                isDimensionSmall
                isHorizontalOrientation
                description={aromas?.[0]?.description}
                isPearlType={isPearlType}
                image={
                  isAromaNoteSecondaryAddon && aromas?.[0]?.secondary_image
                    ? aromas?.[0]?.secondary_image
                    : aromas?.[0]?.image
                }
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PortfolioCard;
