import { FC } from 'react';

import { localeLegacy } from '@lib/tools/locale/source/pmi/legacy';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

interface DisclaimerPropsType {
  privacyLink: string;
  text?: JSX.Element | null;
}

const Disclaimer: FC<DisclaimerPropsType> = ({ privacyLink, text = null }) => (
  <div className="taste-advisor-disclaimer text-center">
    <div className="taste-advisor-p3 mx-auto d-block">
      {text || (
        <div>
          <LocaleFragment message={localeLegacy.disclaimer.privacyTextOne} />
          &nbsp;
          <a
            className="text-decoration-underline"
            href={privacyLink || 'https://www.pmiprivacy.com/consumer/'}
            rel="noopener noreferrer"
            target="_blank"
          >
            <LocaleFragment message={localeLegacy.disclaimer.privacyHyperLink} />
          </a>
        </div>
      )}
    </div>
  </div>
);

export default Disclaimer;
