import classNames from 'classnames';
import React from 'react';

import { useApp } from '@lib/core/service/hooks';
import { localeLegacy } from '@lib/tools/locale/source/pmi/legacy';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import { isOpenIntegration } from '@components/pmi/src/utils';

const InvalidVerificationLinkGate = (): JSX.Element => {
  const { isLayoutRightToLeft } = useApp();
  return (
    <div className="tasteAdvisor-gate">
      <div className="popup d-flex position-absolute w-100vw h-100vh top-0px left-0px">
        <div className="d-flex mx-auto max-width-600px">
          <div
            className={classNames(
              'd-flex flex-column text-center text-white text-uppercase font-ta-v2-global-caption-small',
              {
                'letter-spacing-zero': isLayoutRightToLeft,
              },
            )}
          >
            <p>
              <LocaleFragment message={localeLegacy.remoteAccessBlock.title} />
            </p>
            <p>
              {isOpenIntegration() ? (
                <LocaleFragment message={localeLegacy.remoteAccessBlock.title} />
              ) : (
                <LocaleFragment message={localeLegacy.remoteAccessBlock.text} />
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export { InvalidVerificationLinkGate as InvalidVerificationLinkGateWithoutMemo };
export default React.memo(InvalidVerificationLinkGate);
