import cn from 'classnames';
import { Dispatch, FC, SetStateAction } from 'react';

import { TQuizAnswerData } from '@lib/core/quizzes/types';
import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import {
  isQuizAnswerTagProductStateDiscontinued,
  isQuizAnswerTagProductStateToBeDiscontinued,
} from '@lib/tools/shared/pmi/quizzes/filters';

import AnswerSectionHeader from '@components/pmi/src/organisms/Quiz/AnswerSectionHeader/AnswerSectionHeader';
import AnswerItem from '@components/pmi/src/organisms/Quiz/QDS8002/AnswerItem/AnswerItem';
import { defineLongWord } from '@components/pmi/src/utils';

interface Props {
  answers: TQuizAnswerData[];
  maxAnswersChoices: number;
  setAnswersArray: Dispatch<SetStateAction<string[]>>;
  answersArray: string[];
  handleConfirmAnswer: (answerId: string, callback: () => void) => void;
  isLight?: boolean;
  currentPrimaryColor: string;
  isIgnoreProductSeparationAddon?: boolean;
}

const AnswerSection: FC<Props> = ({
  handleConfirmAnswer,
  answers,
  maxAnswersChoices,
  answersArray,
  setAnswersArray,
  isLight = false,
  currentPrimaryColor,
  isIgnoreProductSeparationAddon,
}) => {
  const availableAnswerProducts = [];
  const discontinuedAnswerProducts = [];
  const toBeDiscontinuedAnswerProducts = [];

  answers.forEach(answer => {
    const answerId = Object.keys(answer)[0];
    if (answer[answerId].tags?.some(isQuizAnswerTagProductStateDiscontinued)) {
      discontinuedAnswerProducts.push(answer);
    } else if (answer[answerId].tags?.some(isQuizAnswerTagProductStateToBeDiscontinued)) {
      toBeDiscontinuedAnswerProducts.push(answer);
    } else {
      availableAnswerProducts.push(answer);
    }
  });

  const isLongWordInAnswer = answers.some(quizAnswer => defineLongWord(quizAnswer[Object.keys(quizAnswer)[0]]?.text));
  const isWideSize = answers.length <= 4 || isLongWordInAnswer;

  return (
    <div className="ta-v2-qds8002-answer-section-wrapper">
      <AnswerSectionHeader
        isLight={isLight}
        maxChoicesCount={maxAnswersChoices}
        selectedChoicesCount={answersArray?.length || 0}
      />
      {(toBeDiscontinuedAnswerProducts.length > 0 || discontinuedAnswerProducts.length > 0) &&
        !isIgnoreProductSeparationAddon && (
          <div
            className={cn('font-ta-v2-global-caption-small ta-v2-answer-section-header-small', {
              isLight,
            })}
          >
            <LocaleFragment message={localeV2.quiz.availableProducts} />
          </div>
        )}
      {isIgnoreProductSeparationAddon ? (
        <div className="ta-v2-qds8002-answer-section">
          {answers.map(answer => {
            const answerId = Object.keys(answer)[0];
            return (
              <AnswerItem
                key={answerId}
                answerId={answerId}
                answerImage={answer[answerId].image}
                answerText={answer[answerId].text}
                answersArray={answersArray}
                currentPrimaryColor={currentPrimaryColor}
                handleConfirmAnswer={handleConfirmAnswer}
                isLight={isLight}
                isWideSize={isWideSize}
                maxAnswersChoices={maxAnswersChoices}
                setAnswersArray={setAnswersArray}
              />
            );
          })}
        </div>
      ) : (
        <>
          <div className="ta-v2-qds8002-answer-section">
            {availableAnswerProducts.map(answer => {
              const answerId = Object.keys(answer)[0];
              return (
                <AnswerItem
                  key={answerId}
                  answerId={answerId}
                  answerImage={answer[answerId].image}
                  answerText={answer[answerId].text}
                  answersArray={answersArray}
                  currentPrimaryColor={currentPrimaryColor}
                  handleConfirmAnswer={handleConfirmAnswer}
                  isLight={isLight}
                  isWideSize={isWideSize}
                  maxAnswersChoices={maxAnswersChoices}
                  setAnswersArray={setAnswersArray}
                />
              );
            })}
          </div>

          {discontinuedAnswerProducts.length > 0 && (
            <>
              <div
                className={cn('margin-top-28px font-ta-v2-global-caption-small ta-v2-answer-section-header-small', {
                  isLight,
                })}
              >
                <div>
                  <LocaleFragment message={localeV2.quiz.discontinuedProducts} />
                </div>
              </div>
              <div className="ta-v2-qds8002-answer-section">
                {discontinuedAnswerProducts.map(answer => {
                  const answerId = Object.keys(answer)[0];
                  return (
                    <AnswerItem
                      key={answerId}
                      answerId={answerId}
                      answerImage={answer[answerId].image}
                      answerText={answer[answerId].text}
                      answersArray={answersArray}
                      currentPrimaryColor={currentPrimaryColor}
                      handleConfirmAnswer={handleConfirmAnswer}
                      isLight={isLight}
                      isWideSize={isWideSize}
                      maxAnswersChoices={maxAnswersChoices}
                      setAnswersArray={setAnswersArray}
                    />
                  );
                })}
              </div>
            </>
          )}

          {toBeDiscontinuedAnswerProducts.length > 0 && (
            <>
              <div
                className={cn('margin-top-28px font-ta-v2-global-caption-small ta-v2-answer-section-header-small', {
                  isLight,
                })}
              >
                <div>
                  <LocaleFragment message={localeV2.quiz.toBeDiscontinuedProducts} />
                </div>
              </div>
              <div className="ta-v2-qds8002-answer-section">
                {toBeDiscontinuedAnswerProducts.map(answer => {
                  const answerId = Object.keys(answer)[0];
                  return (
                    <AnswerItem
                      key={answerId}
                      answerId={answerId}
                      answerImage={answer[answerId].image}
                      answerText={answer[answerId].text}
                      answersArray={answersArray}
                      currentPrimaryColor={currentPrimaryColor}
                      handleConfirmAnswer={handleConfirmAnswer}
                      isLight={isLight}
                      isWideSize={isWideSize}
                      maxAnswersChoices={maxAnswersChoices}
                      setAnswersArray={setAnswersArray}
                    />
                  );
                })}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AnswerSection;
