import { defineMessages } from '@formatjs/intl';

export const localeLegacy = {
  accessKeyGenerator: defineMessages({
    copyToClipboard: {
      defaultMessage: 'Copy to clipboard',
      id: 'taste_advisor.access_key_generator.copy_to_clipboard',
    },
    expirationPeriod: {
      defaultMessage: 'expiration period',
      id: 'taste_advisor.access_key_generator.expiration_period',
    },
    fromTheMoment: {
      defaultMessage: 'From the moment the link is generated',
      id: 'taste_advisor.access_key_generator.from_the_moment',
    },
    generateLink: {
      defaultMessage: 'generate link',
      id: 'taste_advisor.access_key_generator.generate_link',
    },
    header: {
      defaultMessage: 'Links generator',
      id: 'taste_advisor.access_key_generator.header',
    },
    hours: { defaultMessage: 'Hours', id: 'taste_advisor.access_key_generator.hours' },
    month: { defaultMessage: 'month', id: 'taste_advisor.access_key_generator.month' },
    months: { defaultMessage: 'months', id: 'taste_advisor.access_key_generator.months' },
    numberOfUses: {
      defaultMessage: 'Number of uses',
      id: 'taste_advisor.access_key_generator.number_of_uses',
    },
    week: { defaultMessage: 'week', id: 'taste_advisor.access_key_generator.week' },
    weeks: { defaultMessage: 'weeks', id: 'taste_advisor.access_key_generator.weeks' },
    year: { defaultMessage: 'year', id: 'taste_advisor.access_key_generator.year' },
  }),
  ageGate: defineMessages({
    accessBlocked: {
      defaultMessage: 'You must be { ageLimit } or over to access materials on this site.',
      id: 'taste_advisor_age_gate.not_available',
    },
    april: { defaultMessage: 'April', id: 'taste_advisor_age_gate.month_april' },
    august: { defaultMessage: 'August', id: 'taste_advisor_age_gate.month_august' },
    confirmBtn: {
      defaultMessage: 'Confirm',
      id: 'taste_advisor_age_gate.Confirm-btn',
    },
    december: { defaultMessage: 'December', id: 'taste_advisor_age_gate.month_december' },
    descriptionOne: {
      defaultMessage: 'Customer Support Center - LiveChat on iqos.ro, daily from 8:00 to 22:00',
      id: 'taste_advisor_age_gate.description_1',
    },
    descriptionTwo: {
      defaultMessage: 'Copyright 2020 - All rights reserved',
      id: 'taste_advisor_age_gate.description_2',
    },
    february: { defaultMessage: 'February', id: 'taste_advisor_age_gate.month_february' },
    january: { defaultMessage: 'January', id: 'taste_advisor_age_gate.month_january' },
    july: { defaultMessage: 'July', id: 'taste_advisor_age_gate.month_july' },
    june: { defaultMessage: 'June', id: 'taste_advisor_age_gate.month_june' },
    march: { defaultMessage: 'March', id: 'taste_advisor_age_gate.month_march' },
    may: { defaultMessage: 'May', id: 'taste_advisor_age_gate.month_may' },
    month: { defaultMessage: 'Month', id: 'age_gate.month' },
    november: { defaultMessage: 'November', id: 'taste_advisor_age_gate.month_november' },
    october: { defaultMessage: 'October', id: 'taste_advisor_age_gate.month_october' },
    september: { defaultMessage: 'September', id: 'taste_advisor_age_gate.month_september' },
    titleOne: {
      defaultMessage:
        'Are you { ageLimit } years old or older and a smoker or user of other products containing nicotine?',
      id: 'taste_advisor_age_gate.title_1',
    },
    titleTwo: {
      defaultMessage: 'Insert your date of birth',
      id: 'taste_advisor_age_gate.title_2',
    },
    year: { defaultMessage: 'Year', id: 'age_gate.year' },
  }),
  common: defineMessages({
    disableRetailerPageText1: {
      defaultMessage: 'The page you are trying to reach is no longer active.',
      id: 'taste-advisor.disable-retailer-page.text_1',
    },
    disableRetailerPageText2: {
      defaultMessage: "To Access the Flavour Advisor tool, please go to your country's IQOS website.",
      id: 'taste-advisor.disable-retailer-page.text_2',
    },
  }),
  disclaimer: defineMessages({
    privacyHyperLink: {
      defaultMessage: 'PMI privacy policy',
      id: 'taste-advisor.disclaimer.pmi_privacy_hyperlink',
    },
    privacyTextOne: {
      defaultMessage: 'Your data will be processed in accordance to the',
      id: 'taste-advisor.disclaimer.privacy_part_one',
    },
  }),
  geoBlock: defineMessages({
    title1: {
      defaultMessage: 'We are sorry.',
      id: 'taste_advisor_geoblock.geoblock_title1',
    },
    title2: {
      defaultMessage: 'Please note this website is intended for another',
      id: 'taste_advisor_geoblock.geoblock_title2',
    },
    title3: {
      defaultMessage: 'Country. In order to ensure compliance with local',
      id: 'taste_advisor_geoblock.geoblock_title3',
    },
    title4: {
      defaultMessage: 'legal requirements, you are not allowed to access it.',
      id: 'taste_advisor_geoblock.geoblock_title4',
    },
  }),
  healthWarning: defineMessages({
    iqos3HealthWarning: {
      defaultMessage:
        'This product is not risk free and provides nicotine, which is addictive. Only for use by adults.',
      id: 'taste-advisor.iqos3.health_warning_footer',
    },
    iqos3HealthWarningFooterToIsraelWarning: {
      defaultMessage: 'WARNING:',
      id: 'taste-advisor.iqos3.health_warning_footer_to_israel_warning',
    },
    iqos3HealthWarningFooterToIsraelWarningText: {
      defaultMessage: 'Medical researches have determined that cigarettes cause addictions',
      id: 'taste-advisor.iqos3.health_warning_footer_to_israel_warning_text',
    },
    landingBottomBlack: {
      defaultMessage: 'Tobacco products damage your health and are addictive.',
      id: 'taste-advisor.health_warning_landing_bottom_black',
    },
    retailerPageMultilingualOne: {
      defaultMessage: 'Dieses Tabakerzeugnis kann Ihre Gesundheit schädigen und macht abhängig.',
      id: 'taste-advisor.retailer_page.health_warning_multilingual.one',
    },
    retailerPageMultilingualThree: {
      defaultMessage: 'Questo prodotto del tabacco può nuocere alla tua salute e provoca dipendenza.',
      id: 'taste-advisor.retailer_page.health_warning_multilingual.three',
    },
    retailerPageMultilingualTwo: {
      defaultMessage: 'Ce produit du tabac peut nuire à votre santé et crée une dépendance.',
      id: 'taste-advisor.retailer_page.health_warning_multilingual.two',
    },
    retailerPageWarningOne: {
      defaultMessage: 'Your data will be processed in accordance to the',
      id: 'taste-advisor.retailer-page.warning_footer.part_one',
    },
    retailerPageWarningThree: {
      defaultMessage: 'Philip Morris Switzerland Sàrl',
      id: 'taste-advisor.retailer-page.warning_footer.part_three',
    },
    retailerPageWarningTwo: {
      defaultMessage: 'of',
      id: 'taste-advisor.retailer-page.warning_footer.part_two',
    },
    retailerPrepageAgreeBtnText: {
      defaultMessage: 'Agree',
      id: 'taste-advisor.retailer-prepage.agree-button',
    },
    retailerPrepageHealthWarning: {
      defaultMessage: `Smoking by persons under twenty years of age is prohibited by law.
        &nbsp; \n
        Adverse effect on the health of surrounding people cannot be denied for smoke (vapor) from
        Heated Tobacco Products. Smoking is prohibited in non-smoking areas under the Health Promotion Act.
        \n
        Adverse effect on your health cannot be denied for smoke (vapor) from Heated Tobacco Products which
        includes carcinogen and addictive nicotine.`,
      id: 'taste-advisor.retailer-prepage.health-warning',
    },
  }),
  iqos3: defineMessages({
    resultCharacterText: {
      defaultMessage: 'Your taste character is',
      id: 'taste-advisor-iqos3_result_page.your_character_is',
    },
  }),
  remoteAccessBlock: defineMessages({
    openIntegration: {
      defaultMessage:
        'The expiration time for the page has passed.Please reload the experience using the "start over" button above to refresh.',
      id: 'ta.remote_access_block.text.open_integration',
    },
    text: {
      defaultMessage:
        'The link to the flavor advisor experience you tried to access has expired. Please contact IQOS customer care to request a new link.',
      id: 'ta.remote_access_block.text',
    },
    title: {
      defaultMessage: 'We are sorry.',
      id: 'ta.remote_access_block.title',
    },
  }),
};
