import { TEssence, TEssenceGroups } from '@lib/core/characters/types/essences';
import { selectServiceProductCategory } from '@lib/core/service/selectors';
import { store } from '@lib/core/service/store';
import {
  HEETS_ESSENCE_AROMA_ID,
  HEETS_ESSENCE_INTENSITY_ID,
  TEREA_ESSENCE_AROMA_ID,
  TEREA_ESSENCE_INTENSITY_ID,
} from '@lib/tools/shared/pmi/essences/consts';

export const isCurrentEssenceGroup = (essenceGroup: TEssenceGroups): boolean => {
  const productCategory = selectServiceProductCategory(store?.getState());
  return essenceGroup.name === productCategory;
};

export const isEssenceAroma = (essence: TEssence): boolean =>
  essence.identifier === TEREA_ESSENCE_AROMA_ID || essence.identifier === HEETS_ESSENCE_AROMA_ID;

export const isEssenceIntensity = (essence: TEssence): boolean =>
  essence.identifier === TEREA_ESSENCE_INTENSITY_ID || essence.identifier === HEETS_ESSENCE_INTENSITY_ID;
