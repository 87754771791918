import cn from 'classnames';
import { FC } from 'react';

import LogoImg from '@app/pmi/src/assets/media/start_page_iqos_logo.png';

import { IRetailerLanguage } from '@lib/core/retailers/types/retailer';
import { useApp } from '@lib/core/service/hooks';
import { localeTEREA } from '@lib/tools/locale/source/pmi/terea';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { useAddons } from '@lib/tools/views/hooks';

import Disclaimer from '@components/pmi/src/common/Disclaimer';
import LanguageSwitcherComponent from '@components/pmi/src/molecules/LanguageSwitcher';

interface Props {
  productCategories: string[];
  supportedLanguages: IRetailerLanguage[];
  handleSelectProductCategory: (el: string) => void;
}

const ProductCategorySelectorComponent: FC<Props> = ({
  productCategories,
  supportedLanguages,
  handleSelectProductCategory,
}) => {
  const { isHideIqosAddon } = useAddons();
  const { isLayoutRightToLeft } = useApp();

  const titleLength = localeTEREA.landing.flavours.defaultMessage.length;

  return (
    <>
      <LanguageSwitcherComponent
        className="taste-advisor-start-page-v2-desktop-language-switcher-wrapper"
        supportedLanguages={supportedLanguages}
      />

      <div
        className={cn('taste-advisor-start-page v2', {
          'rtl-orientation': isLayoutRightToLeft,
        })}
      >
        <LanguageSwitcherComponent
          className="taste-advisor-start-page-v2-mobile-language-switcher-wrapper"
          supportedLanguages={supportedLanguages}
        />

        <div className="flex-grow-1 d-flex">
          <div className="main-part">
            {!isHideIqosAddon && (
              <img
                alt="Logo"
                src={LogoImg}
                className={cn('logo-img', {
                  'ltr-orientation': !isLayoutRightToLeft,
                  'rtl-orientation': isLayoutRightToLeft,
                })}
              />
            )}
            <h2 className="ta-start-page-h3-text mb-0">
              <LocaleFragment message={localeTEREA.landing.findYourWayTo} />
            </h2>

            <h2
              className={cn('mt-0 title font-ta-v2 font-weight-bold', {
                'font-1': titleLength < 12,
                'font-2': titleLength >= 12 && titleLength < 16,
                'font-3': titleLength >= 16 && titleLength < 20,
                'font-4': titleLength >= 20,
              })}
            >
              <LocaleFragment message={localeTEREA.landing.flavours} />
            </h2>
            <p className="ta-start-page-text font-weight-bold mb-0 discover">
              <LocaleFragment message={localeTEREA.landing.selectorDescriptionText} />
            </p>
            <div className="product-selection-section mx-0 d-flex">
              {productCategories.map(productCategory => (
                <button
                  key={productCategory}
                  type="button"
                  onClick={() => {
                    handleSelectProductCategory(productCategory);
                  }}
                >
                  {productCategory === 'veev' ? 'veev one' : productCategory}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center disclaimer-wrapper v2">
          <Disclaimer
            privacyLink=""
            text={
              <div className="d-flex flex-column px-0">
                <span>
                  <LocaleFragment message={localeTEREA.landing.selectorDisclaimerText} />
                </span>
                <span>
                  <LocaleFragment message={localeTEREA.landing.privacyText} />
                  &nbsp;
                  <a
                    className="text-decoration-underline"
                    href="https://www.pmiprivacy.com/consumer/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <LocaleFragment message={localeTEREA.landing.privacyLink} />
                  </a>
                </span>
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};

export default ProductCategorySelectorComponent;
