import cn from 'classnames';
import { FC, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { TProductInstance, TProductSetsProducts } from '@lib/core/products/types';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { useApp } from '@lib/core/service/hooks';
import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { parseTereaVariants, showProductWithCoolingEssence } from '@lib/tools/shared/pmi/products';
import { isProductLevia, isProductSentia } from '@lib/tools/shared/pmi/products/filters';
import { HEETS, TEREA } from '@lib/tools/shared/pmi/retailers/consts';
import { useDisclosure, useEqualElementsHeightV2, useHeight } from '@lib/tools/views/hooks';

import ContinueBtn from '@components/pmi/src/atoms/CTA/Button';
import TertiaryBtn from '@components/pmi/src/atoms/CTA/Navigator';
import HealthWarningFooterToIsrael from '@components/pmi/src/common/HealthWarningFooterToIsrael';
import Header from '@components/pmi/src/organisms/Header/Header';
import HealthWarningFooter from '@components/pmi/src/organisms/HealthWarning/HealthWarning';
import LeviaBanner from '@components/pmi/src/organisms/Results/Banner/Levia/LeviaBanner';
import BundlesProducts from '@components/pmi/src/organisms/Results/BundlesProduct/BundlesProducts';
import MentholDisclaimer from '@components/pmi/src/organisms/Results/MentholDisclaimer/MentholDisclaimer';
import ResultLongDisclaimer from '@components/pmi/src/organisms/Results/ResultLongDisclaimer/ResultLongDisclaimer';
import PortfolioSection from '@components/pmi/src/templates/Modals/PortfolioSection/PortfolioSection';
import { handleEcommerceSeeAllFlavours } from '@components/pmi/src/utils';

import 'swiper/css';
import 'swiper/css/pagination';

type Props = {
  productSetList: TProductSetsProducts[];
  gprls: TProductInstance[];
  className?: string;
  goBack: () => void;
  getMoreSuggestion: () => void;
  isTereaEcommerce: boolean;
  handleBundleProductFeedback: (data: Record<string, string>) => void;
  selectedProductCategory: string;
  isAromaNotesDisabledAddon: boolean;
  isAromaNoteSecondaryAddon: boolean;
  isAddToCartAddon: boolean;
  isEnableBuyEcommerceAddon: boolean;
  isEssenceAromaDisabledAddon: boolean;
  isEssenceBodyDisabledAddon: boolean;
  isEssenceCoolingDisabledAddon: boolean;
  isRestrictedBundlesPageAddon: boolean;
  isRestrictedProductCardsAddon: boolean;
  isTereaHideEssencesAddon: boolean;
  isTobaccoExperienceHiddenAddon: boolean;
  isProductLongDescriptionHiddenAddon: boolean;
  isProductShortDescriptionHiddenAddon: boolean;
  currentPrimaryColor: string;
  minQuantity: number;
  maxQuantity: number;
  isShowHealthWarningFooter: boolean;
  isSentiaEnabled?: boolean;
  isMentholDisclaimerAddon: boolean;
  isResultLongDisclaimerAddon: boolean;
  isHealthWarningFooterToIsraelAddon?: boolean;
  isEnableLeviaBannerAddon: boolean;
  isRestrictedRecommendationAddon: boolean;
};

const RecommendedProducts: FC<Props> = ({
  gprls,
  productSetList,
  className,
  goBack,
  getMoreSuggestion,
  selectedProductCategory,
  isAromaNotesDisabledAddon,
  isAromaNoteSecondaryAddon,
  isAddToCartAddon,
  isEnableBuyEcommerceAddon,
  isEssenceAromaDisabledAddon,
  isEssenceBodyDisabledAddon,
  isEssenceCoolingDisabledAddon,
  isRestrictedBundlesPageAddon,
  isRestrictedProductCardsAddon,
  isTereaHideEssencesAddon,
  isTobaccoExperienceHiddenAddon,
  isTereaEcommerce,
  handleBundleProductFeedback,
  currentPrimaryColor,
  maxQuantity,
  minQuantity,
  isProductLongDescriptionHiddenAddon,
  isProductShortDescriptionHiddenAddon,
  isShowHealthWarningFooter,
  isSentiaEnabled,
  isMentholDisclaimerAddon,
  isHealthWarningFooterToIsraelAddon,
  isResultLongDisclaimerAddon,
  isEnableLeviaBannerAddon,
  isRestrictedRecommendationAddon,
}) => {
  const [footerRef, paddingForFooter] = useHeight<HTMLDivElement>();
  const [currentSwiperSlideNumber, setSwiperSlideNumber] = useState(0);
  const [isOpen, { open, close }] = useDisclosure();
  const isDesktopLargeResolution = useMediaQuery({ minWidth: 1440 });
  const isDesktopSmallResolution = useMediaQuery({ maxWidth: 1439, minWidth: 1025 });
  const isTabletResolution = useMediaQuery({ maxWidth: 1024, minWidth: 768 });
  const isMobileLargelResolution = useMediaQuery({ maxWidth: 767, minWidth: 551 });
  const isMobileSmallResolution = useMediaQuery({ maxWidth: 550 });
  const { isRetailerLocationStoreTypeEcommerce } = useRetailerLocation();
  const productSetList0 = productSetList?.[0]?.products ?? [];
  const productSetList1 = productSetList?.[1]?.products ?? [];

  const isProductLeviaInSet = (products: TProductInstance[]) =>
    Array.isArray(products) && products.some(isProductLevia);

  const isLeviaBannerAvailable = isProductLeviaInSet(productSetList0) || isProductLeviaInSet(productSetList1);

  const isLeviaSeparateCtaCondition = isLeviaBannerAvailable && isRetailerLocationStoreTypeEcommerce;

  useEqualElementsHeightV2(['ta-v2-bundles-page-header-wrapper']);
  let slideSpaceBetween = 17;
  if (isDesktopLargeResolution) {
    slideSpaceBetween = 243;
  }

  if (isDesktopSmallResolution) {
    slideSpaceBetween = 160;
  }

  if (isTabletResolution) {
    slideSpaceBetween = 32;
  }

  if (isMobileLargelResolution) {
    slideSpaceBetween = 90;
  }

  if (isMobileSmallResolution) {
    slideSpaceBetween = 17;
  }

  let bundlesBackground = '';

  if (isRestrictedBundlesPageAddon && selectedProductCategory === TEREA) {
    bundlesBackground = '#51BAB0';
  } else if (isRestrictedBundlesPageAddon && selectedProductCategory === HEETS) {
    bundlesBackground = 'transparent';
  } else {
    bundlesBackground = `url(${productSetList[currentSwiperSlideNumber].image})`;
  }

  const bundlesPageStyles = {
    background: bundlesBackground,
    paddingBottom: paddingForFooter,
  };

  const { isLayoutRightToLeft } = useApp();

  const [filteredGprls, setFilteredGprls] = useState([]);

  const { orderedSentiaProducts, orderedTereaProducts } = parseTereaVariants(gprls);

  const isProductWithCoolingEssencePresent =
    productSetList.some(productSet => productSet.products.some(gprl => showProductWithCoolingEssence(gprl))) &&
    !isEssenceCoolingDisabledAddon;

  const handleSeeAllTerea = () => {
    open();
    setFilteredGprls(orderedTereaProducts);
  };

  const handleSeeAllSentia = () => {
    open();
    setFilteredGprls(orderedSentiaProducts);
  };

  const getFilteredCtaButton = (products: TProductInstance[]) => {
    if (isSentiaEnabled) {
      return (
        <div className="ta-v2-bundles-page-cta-group">
          {products.map((gprl, i) => {
            const btnText = isProductSentia(gprl) ? localeV2.common.seeAllSentia : localeV2.common.seeAllTerea;
            const handleClick = isProductSentia(gprl) ? handleSeeAllSentia : handleSeeAllTerea;
            return (
              <div key={i} className="ta-v2-bundles-page-cta-group-terea">
                <TertiaryBtn
                  btnText={<LocaleFragment message={btnText} />}
                  className="margin-top-24px text-underlined"
                  direction="right"
                  isDark={false}
                  isLayoutRightToLeft={isLayoutRightToLeft}
                  onBtnClick={isRetailerLocationStoreTypeEcommerce ? handleEcommerceSeeAllFlavours : handleClick}
                />
              </div>
            );
          })}
        </div>
      );
    }

    if (isLeviaSeparateCtaCondition) {
      const isCheckBothProducts = products.some(isProductLevia);
      return (
        <div className="ta-v2-bundles-page-cta-group">
          {isCheckBothProducts ? (
            <>
              {products.map((gprl, i) => {
                const btnText = isProductLevia(gprl) ? localeV2.common.seeAllLevia : localeV2.common.seeAllTerea;
                return (
                  <div key={i} className="ta-v2-bundles-page-cta-group-terea">
                    <TertiaryBtn
                      btnText={<LocaleFragment message={btnText} />}
                      className="margin-top-24px text-underlined"
                      direction="right"
                      isDark={false}
                      isLayoutRightToLeft={isLayoutRightToLeft}
                      onBtnClick={() => {
                        if (isRetailerLocationStoreTypeEcommerce) handleEcommerceSeeAllFlavours(true);
                        else open();
                      }}
                    />
                  </div>
                );
              })}
            </>
          ) : (
            <div className="ta-v2-bundles-page-cta-group-terea">
              <TertiaryBtn
                btnText={<LocaleFragment message={localeV2.common.seeAllFlavours} />}
                className="margin-top-24px"
                direction="right"
                isDark={false}
                isLayoutRightToLeft={isLayoutRightToLeft}
                onBtnClick={isRetailerLocationStoreTypeEcommerce ? handleEcommerceSeeAllFlavours : open}
              />
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <div
      style={bundlesPageStyles}
      className={cn(
        `ta-v2-bundles-page ${isHealthWarningFooterToIsraelAddon ? 'padding-bottom-30vh minh-101vh' : 'minh-100vh'}`,
        className,
      )}
    >
      <div className="ta-v2-bundles-page-background-overlay" />

      <div className="ta-v2-bundles-page-container">
        <div
          className={cn('ta-v2-bundles-page-header-wrapper w-100', {
            'ta-v2-bundles-page-header-wrapper-hide': currentSwiperSlideNumber === 1,
          })}
        >
          <Header
            isHideProgressBar
            caption={localeV2.bundles.bundleOneSubtitle}
            className="ta-v2-bundles-page-header-style"
            currentPrimaryColor={currentPrimaryColor}
            isBackButtonEnabled={false}
            isBackButtonVisible={false}
            isLoading={false}
            title={localeV2.bundles.bundleOneTitle}
          />
        </div>

        <div
          className={cn('ta-v2-bundles-page-header-wrapper w-100', {
            'ta-v2-bundles-page-header-wrapper-hide': currentSwiperSlideNumber === 0,
          })}
        >
          <Header
            isHideProgressBar
            caption={localeV2.bundles.bundleTwoSubtitle}
            className="ta-v2-bundles-page-header-style"
            currentPrimaryColor={currentPrimaryColor}
            isBackButtonEnabled={false}
            isBackButtonVisible={false}
            isLoading={false}
            title={localeV2.bundles.bundleTwoTitle}
          />
        </div>
        {!!productSetList.length && (
          <Swiper
            centeredSlides
            className="swiper"
            modules={[Pagination]}
            slidesPerView="auto"
            spaceBetween={slideSpaceBetween}
            pagination={{
              bulletActiveClass: 'pagination-bullet-active',
              bulletClass: 'pagination-bullet',
              clickable: true,
              el: `.${'ta-v2-bundles-page-pagination-container'}`,
              type: 'bullets',
            }}
            onSlideChange={slide => {
              return slide.realIndex !== currentSwiperSlideNumber ? setSwiperSlideNumber(slide.realIndex) : null;
            }}
          >
            <SwiperSlide className="swiper-slide">
              <BundlesProducts
                bundlesProducts={productSetList[0].products}
                handleBundleProductFeedback={handleBundleProductFeedback}
                isAddToCartAddon={isAddToCartAddon}
                isAromaNoteSecondaryAddon={isAromaNoteSecondaryAddon}
                isAromaNotesDisabledAddon={isAromaNotesDisabledAddon}
                isBtnDisable={currentSwiperSlideNumber === 1}
                isEnableBuyEcommerceAddon={isEnableBuyEcommerceAddon}
                isEssenceAromaDisabledAddon={isEssenceAromaDisabledAddon}
                isEssenceBodyDisabledAddon={isEssenceBodyDisabledAddon}
                isEssenceCoolingDisabledAddon={isEssenceCoolingDisabledAddon}
                isMentholDisclaimerAddon={isMentholDisclaimerAddon}
                isProductLongDescriptionHiddenAddon={isProductLongDescriptionHiddenAddon}
                isProductShortDescriptionHiddenAddon={isProductShortDescriptionHiddenAddon}
                isRestrictedProductCardsAddon={isRestrictedProductCardsAddon}
                isRestrictedRecommendationAddon={isRestrictedRecommendationAddon}
                isTereaHideEssencesAddon={isTereaHideEssencesAddon}
                isTobaccoExperienceHiddenAddon={isTobaccoExperienceHiddenAddon}
                maxQuantity={maxQuantity}
                minQuantity={minQuantity}
                productSetID={productSetList[0].product_set_id}
                selectedProductCategory={selectedProductCategory}
              />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <BundlesProducts
                bundlesProducts={productSetList[1].products}
                handleBundleProductFeedback={handleBundleProductFeedback}
                isAddToCartAddon={isAddToCartAddon}
                isAromaNoteSecondaryAddon={isAromaNoteSecondaryAddon}
                isAromaNotesDisabledAddon={isAromaNotesDisabledAddon}
                isBtnDisable={currentSwiperSlideNumber === 0}
                isEnableBuyEcommerceAddon={isEnableBuyEcommerceAddon}
                isEssenceAromaDisabledAddon={isEssenceAromaDisabledAddon}
                isEssenceBodyDisabledAddon={isEssenceBodyDisabledAddon}
                isEssenceCoolingDisabledAddon={isEssenceCoolingDisabledAddon}
                isMentholDisclaimerAddon={isMentholDisclaimerAddon}
                isProductLongDescriptionHiddenAddon={isProductLongDescriptionHiddenAddon}
                isProductShortDescriptionHiddenAddon={isProductShortDescriptionHiddenAddon}
                isRestrictedProductCardsAddon={isRestrictedProductCardsAddon}
                isRestrictedRecommendationAddon={isRestrictedRecommendationAddon}
                isTereaHideEssencesAddon={isTereaHideEssencesAddon}
                isTobaccoExperienceHiddenAddon={isTobaccoExperienceHiddenAddon}
                maxQuantity={maxQuantity}
                minQuantity={minQuantity}
                productSetID={productSetList[1].product_set_id}
                selectedProductCategory={selectedProductCategory}
              />
            </SwiperSlide>
          </Swiper>
        )}
        {getFilteredCtaButton(productSetList[currentSwiperSlideNumber].products)}

        <div className="ta-v2-bundles-page-pagination-container" />
        <div className="ta-v2-bundles-page-btn-container">
          <ContinueBtn
            btnText={<LocaleFragment message={localeV2.bundles.continue} />}
            className="margin-top-24px"
            isDark={false}
            onBtnClick={getMoreSuggestion}
          />

          {!!gprls.length && !isSentiaEnabled && !isLeviaSeparateCtaCondition && (
            <TertiaryBtn
              btnText={<LocaleFragment message={localeV2.common.seeAllFlavours} />}
              className="margin-top-24px"
              direction="right"
              isDark={false}
              isLayoutRightToLeft={isLayoutRightToLeft}
              onBtnClick={isRetailerLocationStoreTypeEcommerce ? handleEcommerceSeeAllFlavours : open}
            />
          )}
          <TertiaryBtn
            btnText={<LocaleFragment message={localeV2.common.back} />}
            className="margin-top-24px margin-bottom-40px text-underlined"
            isDark={false}
            onBtnClick={goBack}
          />
        </div>
        <PortfolioSection
          gprls={isSentiaEnabled ? filteredGprls : gprls}
          isAromaNoteSecondaryAddon={isAromaNoteSecondaryAddon}
          isAromaNotesDisabledAddon={isAromaNotesDisabledAddon}
          isEssenceAromaDisabledAddon={isEssenceAromaDisabledAddon}
          isEssenceBodyDisabledAddon={isEssenceBodyDisabledAddon}
          isEssenceCoolingDisabledAddon={isEssenceCoolingDisabledAddon}
          isMentholDisclaimerAddon={isMentholDisclaimerAddon}
          isOpen={isOpen}
          isProductLongDescriptionHiddenAddon={isProductLongDescriptionHiddenAddon}
          isProductShortDescriptionHiddenAddon={isProductShortDescriptionHiddenAddon}
          isRestrictedProductCardsAddon={isRestrictedProductCardsAddon}
          isRestrictedRecommendationAddon={isRestrictedRecommendationAddon}
          isResultLongDisclaimerAddon={isResultLongDisclaimerAddon}
          isSentiaEnabled={isSentiaEnabled}
          isTereaEcommerce={isTereaEcommerce}
          isTereaHideEssencesAddon={isTereaHideEssencesAddon}
          isTobaccoExperienceHiddenAddon={isTobaccoExperienceHiddenAddon}
          openTereaPortfolio={handleSeeAllTerea}
          selectedProductCategory={selectedProductCategory}
          onClose={close}
        />
        {isMentholDisclaimerAddon && isProductWithCoolingEssencePresent && <MentholDisclaimer isLight />}
        {isResultLongDisclaimerAddon && <ResultLongDisclaimer isLight />}
        {isEnableLeviaBannerAddon && isLeviaBannerAvailable && <LeviaBanner />}
        {isShowHealthWarningFooter && (
          <div ref={footerRef} className="ta-v2-bundles-page-footer">
            <HealthWarningFooter />
          </div>
        )}
      </div>
      {isHealthWarningFooterToIsraelAddon && <HealthWarningFooterToIsrael />}
    </div>
  );
};

export default RecommendedProducts;
