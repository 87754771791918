import { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { GA_EVENT_INTERMEDIATE_PAGE, trackIntermediateRecommendation } from '@app/pmi/src/comms/events';
import { broadcastDomHeightToHost, broadcastUserQuizResultToHost } from '@app/pmi/src/comms/utils';

import { useProducts } from '@lib/core/products/hooks/products';
import { actionGetAllProducts } from '@lib/core/products/slices/products';
import { TProductInstance } from '@lib/core/products/types';
import { useQuizView } from '@lib/core/quizzes/hooks';
import { actionPatchUserQuizMetadata } from '@lib/core/quizzes/slices';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { useApp } from '@lib/core/service/hooks';
import { prependBasename } from '@lib/core/service/utils';
import { POST_MESSAGE_BEST_MATCH } from '@lib/tools/comms/consts';
import { isQuizAnswerTagContextBestMatch } from '@lib/tools/shared/pmi/quizzes/filters';
import { HEETS, TEREA, VEEV } from '@lib/tools/shared/pmi/retailers/consts';
import { useAddons } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import BestMatchPage from '@components/pmi/src/templates/BestMatchPage/BestMatchPage';
import LoadingPageV2 from '@components/pmi/src/templates/LoadingPageV2/LoadingPageV2';
import { checkCurrentPrimaryColor, isWyngEcommerce } from '@components/pmi/src/utils';

const BestMatchContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { productCategory } = useApp();
  const { retailerTags } = useRetailer();
  const { isRetailerLocationStoreTypeEcommerce } = useRetailerLocation();
  const {
    isAddToCartAddon,
    isAromaNoteSecondaryAddon,
    isAromaNotesDisabledAddon,
    isEnableBuyEcommerceAddon,
    isEssenceAromaDisabledAddon,
    isEssenceBodyDisabledAddon,
    isEssenceCoolingDisabledAddon,
    isRestrictedProductCardsAddon,
    isRestrictedResultPageAddon,
    isTereaHideEssencesAddon,
    isTobaccoExperienceHiddenAddon,
    isProductLongDescriptionHiddenAddon,
    isProductShortDescriptionHiddenAddon,
    isHealthWarningFooterAddon,
    isMentholDisclaimerAddon,
    isHealthWarningFooterToIsraelAddon,
    isResultLongDisclaimerAddon,
    isEnableLeviaBannerAddon,
    isRestrictedRecommendationAddon,
  } = useAddons();
  const { productsList, isProductsLoading } = useProducts();
  const { quizViewPausedRoute, quizViewAnswerTags, quizViewDepth } = useQuizView();

  const [bestMatchGprl, setBestMatchGprl] = useState<TProductInstance>();

  const currentPrimaryColor = checkCurrentPrimaryColor();
  const bestMatchProductSlug = quizViewAnswerTags.find(isQuizAnswerTagContextBestMatch)?.name;

  const handleGoToNextQuestion = () => {
    navigate(prependBasename(quizViewPausedRoute));
  };

  useLayoutEffect(() => {
    if (bestMatchGprl) {
      trackIntermediateRecommendation(GA_EVENT_INTERMEDIATE_PAGE.BEST_MATCH, [bestMatchGprl.product?.name]);
      broadcastDomHeightToHost();
    }
  }, [bestMatchGprl]);

  useEffect(() => {
    if (!productsList.length && !isProductsLoading) {
      dispatch(actionGetAllProducts());
    }
  }, [productsList, isProductsLoading]);

  useEffect(() => {
    if (bestMatchProductSlug && productsList.length) {
      const filteredGprl =
        productsList.length && productsList?.filter(gprl => gprl?.product.slug === bestMatchProductSlug)[0];

      if (!filteredGprl) {
        handleGoToNextQuestion();
        return;
      }

      const payload = {
        metadata: {
          best_match_displayed: [filteredGprl.product.identifier],
        },
      };

      dispatch(actionPatchUserQuizMetadata(payload));

      setBestMatchGprl(filteredGprl);

      if (isWyngEcommerce() && filteredGprl) {
        broadcastUserQuizResultToHost(POST_MESSAGE_BEST_MATCH, filteredGprl);
        handleGoToNextQuestion();
      }
    } else {
      navigate(prependBasename(PAGES.ta.landing));
    }
  }, [bestMatchProductSlug]);

  const isBackgroundEnabled = productCategory === TEREA;
  const isVeev = productCategory === VEEV;

  const isShowHealthWarningFooter =
    (!isRetailerLocationStoreTypeEcommerce || (isRetailerLocationStoreTypeEcommerce && isHealthWarningFooterAddon)) &&
    !isHealthWarningFooterToIsraelAddon;

  return bestMatchGprl && !isWyngEcommerce() ? (
    <BestMatchPage
      bestMatchPageBackgroundImage="https://media-staging.taste-advisor-vh.com/media/product_attributes/v2backgrounds/v2_terea_quiz_background.png"
      continueFlavourAnalysis={handleGoToNextQuestion}
      currentPrimaryColor={currentPrimaryColor}
      gprl={bestMatchGprl}
      isAddToCartAddon={isAddToCartAddon}
      isAromaNoteSecondaryAddon={isAromaNoteSecondaryAddon}
      isAromaNotesDisabledAddon={isAromaNotesDisabledAddon}
      isBackgroundEnabled={isBackgroundEnabled}
      isEnableBuyEcommerceAddon={isEnableBuyEcommerceAddon}
      isEnableLeviaBannerAddon={isEnableLeviaBannerAddon}
      isEssenceAromaDisabledAddon={isEssenceAromaDisabledAddon}
      isEssenceBodyDisabledAddon={isEssenceBodyDisabledAddon}
      isEssenceCoolingDisabledAddon={isEssenceCoolingDisabledAddon}
      isHealthWarningFooterToIsraelAddon={isHealthWarningFooterToIsraelAddon}
      isHeetsBackgroundEnabled={productCategory === HEETS}
      isLoading={false}
      isMentholDisclaimerAddon={isMentholDisclaimerAddon}
      isProductLongDescriptionHiddenAddon={isProductLongDescriptionHiddenAddon}
      isProductShortDescriptionHiddenAddon={isProductShortDescriptionHiddenAddon}
      isRestrictedProductCardsAddon={isRestrictedProductCardsAddon}
      isRestrictedRecommendationAddon={isRestrictedRecommendationAddon}
      isRestrictedResultPageAddon={isRestrictedResultPageAddon}
      isResultLongDisclaimerAddon={isResultLongDisclaimerAddon}
      isShowHealthWarningFooter={isShowHealthWarningFooter}
      isTechnicalCaptionEnable={false}
      isTereaHideEssencesAddon={isTereaHideEssencesAddon}
      isTobaccoExperienceHiddenAddon={isTobaccoExperienceHiddenAddon}
      isVeev={isVeev}
      maxQuantity={retailerTags?.ecommerceQuantityRange?.[1]}
      minQuantity={retailerTags?.ecommerceQuantityRange?.[0]}
      noOfAnswersLeft={quizViewDepth + 1}
      selectedProductCategory={productCategory}
    />
  ) : (
    <LoadingPageV2 isHeetsBackgroundEnabled={productCategory === HEETS} />
  );
};

export default BestMatchContainer;
