/**
 * * This file contains custom Google Analytics methods for PMI ecommerce solutions
 */
import { broadcastGoogleAnalyticsEvent } from '@app/pmi/src/comms/utils';

import { TProductInstance } from '@lib/core/products/types';
import { selectQuizViewAnswerTags } from '@lib/core/quizzes/selectors';
import { selectServiceProductCategory } from '@lib/core/service/selectors';
import { store } from '@lib/core/service/store';
import {
  isQuizAnswerTagLanu,
  isQuizAnswerTagLau,
  isQuizAnswerTagLauHeets,
  isQuizAnswerTagLauSentia,
  isQuizAnswerTagLauTerea,
} from '@lib/tools/shared/pmi/quizzes/filters';

export const GA_EVENT_CALL_TO_ACTION = {
  ADD_TO_CART: 'add to cart',
  BUNDLES_PAGE: 'Bundles page view',
  BUY: 'buy',
  CLOSE_QUANTITY_LIST: 'close quantity list',
  GET_MORE_SUGGESTION: 'get more suggestion',
  GO_BACK: 'go back',
  OPEN_QUANTITY_LIST: 'open quantity list',
  PRODUCT_REFERENCE_CARD_COMPLEXITY: 'help text complexity open',
  PRODUCT_REFERENCE_CARD_INTENSITY: 'help text intensity open',
  RESULT_DESCRIPTION: 'result description',
  SEE_ALL_FLAVOURS: 'see all flavours',
  SELECT_QUANTITY: 'select quantity',
  START_OVER: 'start over',
};

export const GA_EVENT_INTERMEDIATE_PAGE = {
  BEST_MATCH: 'Best match page',
  BUNDLES: 'Bundles page',
};

/**
 * 'event': 'quiz_start'
 * 'quizName': '${productCategory} taste advisor'
 */
export const trackQuizInitialization = () => {
  const state = store.getState();
  const productCategory = selectServiceProductCategory(state);

  const payload = {
    event: 'quiz_start',
    quizName: `${productCategory} taste advisor`,
  };

  broadcastGoogleAnalyticsEvent(payload);
};

/**
 * 'event': 'question_display'
 * 'quizName': '${productCategory} taste advisor'
 * 'questionDisplay': 'question ${questionNumber} - ${hashedQuestionId}'
 */
export const trackQuizQuestionDisplay = (questionNumber, questionId) => {
  const state = store.getState();
  const productCategory = selectServiceProductCategory(state);

  const payload = {
    event: 'question_display',
    questionDisplay: `question ${questionNumber} - ${questionId}`,
    quizName: `${productCategory} taste advisor`,
  };

  broadcastGoogleAnalyticsEvent(payload);
};

/**
 * 'event': 'quiz_complete'
 * 'itemUsed': '${logicBasedOnFirstAnswer}'
 * 'productDisplayed': '${item1}, ${item2}, ${item3}'
 * 'quizName': '${productCategory} taste advisor'
 */
export const trackQuizCompletion = (productInstances: TProductInstance[]) => {
  const state = store.getState();
  const productCategory = selectServiceProductCategory(state);
  const quizViewAnswerTags = selectQuizViewAnswerTags(state);

  let itemUsed = 'LAS HEETS';

  if (quizViewAnswerTags.some(isQuizAnswerTagLau)) {
    itemUsed = 'LAU';
  } else if (quizViewAnswerTags.some(isQuizAnswerTagLauHeets)) {
    itemUsed = 'LAU HEETS';
  } else if (quizViewAnswerTags.some(isQuizAnswerTagLauTerea)) {
    itemUsed = 'LAU TEREA';
  } else if (quizViewAnswerTags.some(isQuizAnswerTagLauSentia)) {
    itemUsed = 'LAU SENTIA';
  } else if (quizViewAnswerTags.some(isQuizAnswerTagLanu)) {
    itemUsed = 'LANU';
  }

  const productNames = [];

  productInstances.forEach(productInstance => {
    productNames.push(productInstance.product?.name);
  });

  const payload = {
    event: 'quiz_complete',
    itemUsed: `${itemUsed}`,
    productDisplayed: productNames.join(', '),
    quizName: `${productCategory} taste advisor`,
  };

  broadcastGoogleAnalyticsEvent(payload);
};

/**
 * 'actionTriggered': '<free-text>' // ! Ensure strict type checking
 * 'event': 'quiz_solution_click'
 * 'quizName': '${productCategory} taste advisor'
 */
export const trackCallToAction = (actionName: string) => {
  const state = store.getState();
  const productCategory = selectServiceProductCategory(state);

  const payload = {
    actionTriggered: actionName,
    event: 'quiz_solution_click',
    quizName: `${productCategory} taste advisor`,
  };

  broadcastGoogleAnalyticsEvent(payload);
};

/**
 * 'event': 'recommendation_display'
 * 'itemRecommended': 'question {$questionNumber} - ${hashedQuestionId}'
 * 'quizName': '${productCategory} taste advisor'
 * 'recommendationType': '${Bundles page | Best match page}'
 */
export const trackIntermediateRecommendation = (pageType: string, productNames: string[]) => {
  const state = store.getState();
  const productCategory = selectServiceProductCategory(state);

  const payload = {
    event: 'recommendation_display',
    itemRecommended: `${productNames.join(',')}`,
    quizName: `${productCategory} taste advisor`,
    recommendationType: pageType,
  };

  broadcastGoogleAnalyticsEvent(payload);
};
