import { TCharacter } from '@lib/core/characters/types';
import { TProductCategory } from '@lib/core/products/types';
import { selectServiceProductCategory } from '@lib/core/service/selectors';
import { store } from '@lib/core/service/store';
import { RootState } from '@lib/core/service/types/appStateType';
import {
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';

export const isCharacterForWineCategory = (character: TCharacter): boolean =>
  character.product_category === PRODUCT_CATEGORY_WINE;

export const isCharacterForBeerCategory = (character: TCharacter): boolean =>
  character.product_category === PRODUCT_CATEGORY_BEER;

export const isCharacterForCoffeeCategory = (character: TCharacter): boolean =>
  character.product_category === PRODUCT_CATEGORY_COFFEE;

export const isCharacterByServiceProductCategory = (character: TCharacter): boolean => {
  const state: RootState = store.getState();
  const stateProductCategory = selectServiceProductCategory(state);
  return stateProductCategory === character.product_category;
};

export const isCharacterByProductCategory = (character: TCharacter, productCategory: TProductCategory): boolean =>
  character.product_category === productCategory;

export const isCharacterByIdentifiers = (character: TCharacter, identifiers?: string[]): boolean =>
  identifiers.includes(character.identifier);
