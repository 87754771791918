import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { useRemoteAccess } from '@lib/core/retailers/hooks/remoteAccess';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { actionValidateOpenIntegration } from '@lib/core/retailers/slices/remoteAccess';

import InvalidVerificationLinkGate from '@components/pmi/src/common/InvalidVerificationLinkGate';
import Spinner from '@components/pmi/src/common/Spinner';

import DisclaimerContainer from 'containers/DisclaimerContainer';

function OpenIntegration() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { retailerId } = useRetailer();
  const { isRemoteAccessLoading, isRemoteAccessValid } = useRemoteAccess();

  useEffect(() => {
    const cipherText = searchParams.get('query');
    if (cipherText) {
      dispatch(
        actionValidateOpenIntegration({
          ciphertext: decodeURIComponent(cipherText),
          custom_id: 'open_integration',
          retailer_identifier: retailerId,
        }),
      );
    }
  }, []);

  if (isRemoteAccessLoading || !isRemoteAccessValid) return <Spinner />;

  return isRemoteAccessValid ? <DisclaimerContainer /> : <InvalidVerificationLinkGate />;
}

export default OpenIntegration;
