import cn from 'classnames';
import { FC, useEffect } from 'react';

import { GA_EVENT_CALL_TO_ACTION, trackCallToAction } from '@app/pmi/src/comms/events';

import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import PopUpCard from '@components/pmi/src/organisms/Quiz/PopUp/PopUpCard';

export interface PopUpProps {
  isOpen: boolean;
  onClose: () => void;
  essenceTitle: ILocaleText;
  essenceDescription: string;
  isQDS8003?: boolean;
}

const PopUp: FC<PopUpProps> = ({ isQDS8003 = false, essenceTitle, essenceDescription, isOpen, onClose }) => {
  useEffect(() => {
    if (isOpen)
      trackCallToAction(
        isQDS8003
          ? `${GA_EVENT_CALL_TO_ACTION.PRODUCT_REFERENCE_CARD_INTENSITY}`
          : `${GA_EVENT_CALL_TO_ACTION.PRODUCT_REFERENCE_CARD_COMPLEXITY}`,
      );
  }, [isOpen]);

  return (
    <div
      className={cn('ta-v2-popup', {
        'ta-v2-popup-open': isOpen,
      })}
    >
      <PopUpCard
        essenceDescription={essenceDescription}
        essenceTitle={essenceTitle}
        isOpen={isOpen}
        onClose={onClose}
      />
    </div>
  );
};
export default PopUp;
