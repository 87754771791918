import cn from 'classnames';
import { FC, MutableRefObject, useEffect, useRef, useState } from 'react';

import { ReactComponent as Chevron } from '@app/pmi/src/assets/media/V2/ChevronAddToCart.svg';
import { GA_EVENT_CALL_TO_ACTION, trackCallToAction } from '@app/pmi/src/comms/events';
import { addToEcommerceCart } from '@app/pmi/src/comms/utils';

import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import AddToCartBtn from '@components/pmi/src/atoms/CTA/Button';

export interface AddToCartProps {
  currency: string;
  price: string;
  minQuantity: number;
  maxQuantity: number;
  className?: string;
  isBtnDisable?: boolean;
  ecommerceID: string;
  variantID: string;
  optionID: string;
  isVeev?: boolean;
}

const AddToCart: FC<AddToCartProps> = ({
  className,
  currency,
  price,
  ecommerceID,
  variantID,
  optionID,
  isBtnDisable = false,
  maxQuantity,
  minQuantity,
  isVeev = false,
}) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const minValue = minQuantity || 1;
  const maxValue = maxQuantity || 10;
  const [selectedQuantity, setSelectedQuantity] = useState(minValue);
  const dropdownArray = new Array(maxValue - minValue + 1).fill(null).map((_, i) => i + minValue);
  const dropdownRef: MutableRefObject<any> = useRef();

  useEffect(() => {
    const handler = event => {
      if (dropdownRef.current && !dropdownRef.current?.contains(event.target)) {
        setIsDropDownOpen(false);
      }
    };

    document.addEventListener('click', handler);

    return () => {
      document.removeEventListener('click', handler);
    };
  }, [dropdownRef]);

  return (
    <div className={`ta-v2-add-to-cart-wrapper ${className}`}>
      <div className="ta-v2-add-to-cart-block">
        <div className="ta-v2-add-to-cart-text">
          <div className="font-ta-v2-custom-large-paragraph-bold">
            {currency} {price}
          </div>
          <div className="font-ta-v2-global-hint-text-medium ">
            <LocaleFragment message={localeV2.product.addToCartText} />
          </div>
          {isVeev && (
            <div className="font-ta-v2-global-hint-text-medium ">
              <LocaleFragment message={localeV2.product.addToCartTextVeev} />
            </div>
          )}
        </div>
        <div className="ta-v2-add-to-cart-dropdown">
          <button
            ref={dropdownRef}
            disabled={isBtnDisable}
            type="button"
            className={cn('dropdown', {
              isDropdownOpen: isDropDownOpen,
            })}
            onClick={() => {
              trackCallToAction(
                isDropDownOpen
                  ? `${GA_EVENT_CALL_TO_ACTION.CLOSE_QUANTITY_LIST}`
                  : `${GA_EVENT_CALL_TO_ACTION.OPEN_QUANTITY_LIST}`,
              );
              setIsDropDownOpen(!isDropDownOpen);
            }}
          >
            <span className="font-ta-v2-global-body-large">{selectedQuantity}</span>
            <Chevron className="dropdown-icon" />

            <div className="font-ta-v2-global-body-large dropdown-menu">
              {dropdownArray.map((el, i) => (
                <div
                  key={i}
                  className="dropdown-item"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    trackCallToAction(`${GA_EVENT_CALL_TO_ACTION.SELECT_QUANTITY} ${el}`);
                    setSelectedQuantity(el);
                  }}
                  onKeyDown={() => {
                    trackCallToAction(`${GA_EVENT_CALL_TO_ACTION.SELECT_QUANTITY} ${el}`);
                    setSelectedQuantity(el);
                  }}
                >
                  {el}
                </div>
              ))}
            </div>
          </button>
        </div>
      </div>
      <AddToCartBtn
        isDark
        btnText={<LocaleFragment message={localeV2.product.addToCartBtn} />}
        className="ta-v2-add-to-cart-btn-width"
        isDisabled={isBtnDisable}
        onBtnClick={() => {
          trackCallToAction(`${GA_EVENT_CALL_TO_ACTION.ADD_TO_CART} ${ecommerceID}`);
          addToEcommerceCart(ecommerceID, selectedQuantity, optionID, variantID);
        }}
      />
    </div>
  );
};

export default AddToCart;
