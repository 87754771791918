import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { TQuizAnswerData } from '@lib/core/quizzes/types';
import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { useHeight } from '@lib/tools/views/hooks';

import CTAButton from '@components/pmi/src/atoms/CTA/Button';
import HealthWarningFooterToIsrael from '@components/pmi/src/common/HealthWarningFooterToIsrael';
import { useCurrentQuizBackground } from '@components/pmi/src/hooks/useCurrentQuizBackground';
import QuestionHeader from '@components/pmi/src/organisms/Header/Header';
import HealthWarning from '@components/pmi/src/organisms/HealthWarning/HealthWarning';
import ImagesSlider from '@components/pmi/src/organisms/Quiz/QDS8011/ImagesSlider/ImagesSlider';
import QuizFooter from '@components/pmi/src/organisms/Quiz/QuizFooter/QuizFooter';

interface Props {
  answers: TQuizAnswerData[];
  questionTitle: string;
  questionCaption: string;
  isLoading: boolean;
  questionImage: string;
  goBack: () => void;
  handleConfirmAnswer: (answerId: string | string[], callback: () => void) => void;
  isBackButtonEnabled: boolean;
  isBackButtonVisible: boolean;
  progressPercent: number;
  isHealthWarningQuizAddon: boolean;
  currentPrimaryColor: string;
  isShowRestrictedBackground: boolean;
  isHealthWarningFooterToIsraelAddon?: boolean;
}

const QuizPage = ({
  answers,
  questionTitle,
  questionCaption,
  isLoading,
  questionImage,
  goBack,
  handleConfirmAnswer,
  isBackButtonEnabled,
  isBackButtonVisible,
  progressPercent,
  isHealthWarningQuizAddon,
  currentPrimaryColor,
  isShowRestrictedBackground,
  isHealthWarningFooterToIsraelAddon,
}: Props) => {
  const [footerRef, paddingForFooter] = useHeight<HTMLDivElement>();
  const [checkIfAnswerSelected, setCheckIfAnswerSelected] = useState(false);
  const [answersArray, setAnswersArray] = useState<string[]>([]);
  const isMobileResolution = useMediaQuery({ maxWidth: 767 });
  const currentBackground = useCurrentQuizBackground(questionImage, isShowRestrictedBackground);
  const handleClick = () => {
    handleConfirmAnswer(answersArray, () => {
      setAnswersArray([]);
    });
  };

  const handleSliderSelection = (answerId: string) => {
    setAnswersArray([answerId]);
  };

  return (
    <div
      className={`ta-v2-qds8011-page ${isHealthWarningFooterToIsraelAddon ? 'padding-bottom-30vh minh-101vh' : 'minh-100vh'}`}
      style={{
        background: currentBackground,
        paddingBottom: paddingForFooter,
      }}
    >
      <QuestionHeader
        caption={questionCaption}
        currentPrimaryColor={currentPrimaryColor}
        goBack={goBack}
        isBackButtonEnabled={isBackButtonEnabled}
        isBackButtonVisible={isBackButtonVisible}
        isLoading={isLoading}
        progressPercent={progressPercent}
        title={questionTitle}
      />
      <div className="ta-v2-qds8011-page-answer-section-cover">
        <div className="ta-v2-qds8011-page-answer-section">
          <span className="ta-v2-qds8011-page-answer-section-header">
            <LocaleFragment message={localeV2.quiz.qds8011AnswerSectionHeaderText} />
          </span>
          <div className="ta-v2-qds8011-page-answer-item-container">
            <ImagesSlider
              answers={answers}
              classname="ta-v2-qds8011-page-answer-slider"
              handleSliderSelection={handleSliderSelection}
              setCheckIfAnswerSelected={setCheckIfAnswerSelected}
            />
          </div>
          {!isMobileResolution ? (
            <div className="d-flex justify-content-center w-100">
              <CTAButton
                btnText={<LocaleFragment message={localeV2.common.nextQuestion} />}
                className="ta-v2-cta-btn-margin-large"
                isDark={checkIfAnswerSelected}
                isDisabled={!checkIfAnswerSelected}
                onBtnClick={handleClick}
              />
            </div>
          ) : null}
        </div>
      </div>
      <div ref={footerRef} className="ta-v2-quiz-footer-wrapper">
        {isMobileResolution ? (
          <QuizFooter handleClick={handleClick} isNextButtonEnabled={checkIfAnswerSelected} />
        ) : null}
        {isHealthWarningQuizAddon && <HealthWarning />}
      </div>
      {isHealthWarningFooterToIsraelAddon && <HealthWarningFooterToIsrael />}
    </div>
  );
};

export default QuizPage;
